import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataStorageService } from '../shared/data-storage.service';
import { Club } from './club.model';
import { ClubService } from './club.service';

@Component({
  selector: 'app-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.scss']
})
export class ClubComponent implements OnInit, OnDestroy {

  members = [
    {
      rango: "The Green Best",
      nome: "Michele P.",
      punti: 3390,
      alberi: "33",
      da: "04.2021"
    },
    {
      rango: "The Green Best",
      nome: "Gabriele M.",
      punti: 3290,
      alberi: "33",
      da: "04.2021"
    },
    /*{
      rango: "Sir del Verde",
      nome: "Oliviero F.",
      punti: 740,
      alberi: "2",
      da: "05.2021"
    },
    {
      rango: "Custode Silvano",
      nome: "Adriana M.",
      punti: 640,
      alberi: "1",
      da: "05.2021"
    },*/
    {
      rango: "Custode Silvano",
      nome: "Nausicaa R.",
      punti: 400,
      alberi: "2",
      da: "04.2021"
    },
    /*{
      rango: "Guardiano",
      nome: "Sarah I.",
      punti: 350,
      alberi: "1",
      da: "10.2021"
    },
    {
      rango: "Guardiano",
      nome: "Katia C.",
      punti: 340,
      alberi: "1",
      da: "05.2021"
    },*/
    {
      rango: "The Green Best",
      nome: "Sabina P.",
      punti: 3290,
      alberi: "33",
      da: "04.2021"
    },
    {
      rango: "Guardiano",
      nome: "Mara A.",
      punti: 370,
      alberi: "2",
      da: "04.2021"
    },
    /*{
      rango: "Guardiano",
      nome: "Beatrice O.",
      punti: 280,
      alberi: "1",
      da: "05.2021"
    },
    {
      rango: "Guardiano",
      nome: "Manuela C.",
      punti: 260,
      alberi: "1",
      da: "05.2021"
    },
    {
      rango: "Guardiano",
      nome: "Daela M.",
      punti: 260,
      alberi: "1",
      da: "05.2021"
    },*/
    {
      rango: "Guardiano",
      nome: "Chiara F.",
      punti: 280,
      alberi: "1",
      da: "10.2021"
    },
    {
      rango: "Guardiano",
      nome: "Ismea G.",
      punti:90,
      alberi: "1",
      da: "09.2022"
    },
    /*{
      rango: "Guardiano",
      nome: "Aleksandar J.",
      punti: 250,
      alberi: "1",
      da: "10.2021"
    },
    {
      rango: "Guardiano",
      nome: "Ruben D.",
      punti: 250,
      alberi: "1",
      da: "10.2021"
    },
    {
      rango: "Guardiano",
      nome: "Sarina B.",
      punti: 230,
      alberi: "1",
      da: "05.2021"
    },
    {
      rango: "Guardiano",
      nome: "Greta S.",
      punti: 230,
      alberi: "1",
      da: "10.2021"
    },*/
    {
      rango: "Virgulto",
      nome: "Elena M.",
      punti: 190,
      alberi: "1",
      da: "04.2021"
    },
    /*{
      rango: "Virgulto",
      nome: "Melanie M.",
      punti: 230,
      alberi: "2",
      da: "04.2021"
    },*/
    {
      rango: "Guardiano",
      nome: "Bruna M.",
      punti: 310,
      alberi: "2",
      da: "04.2021"
    },
    /*{
      rango: "Virgulto",
      nome: "Martino B.",
      punti: 160,
      alberi: "1",
      da: "05.2021"
    },
    {
      rango: "Virgulto",
      nome: "Elisa F.",
      punti: 150,
      alberi: "1",
      da: "05.2021"
    },
    {
      rango: "Virgulto",
      nome: "Naomi B.",
      punti: 150,
      alberi: "1",
      da: "10.2021"
    },
    {
      rango: "Virgulto",
      nome: "Grace A.",
      punti: 150,
      alberi: "1",
      da: "10.2021"
    },
    {
      rango: "Virgulto",
      nome: "Manuela M.",
      punti: 150,
      alberi: "1",
      da: "10.2021"
    },
    {
      rango: "Virgulto",
      nome: "Natnael G.",
      punti: 150,
      alberi: "1",
      da: "10.2021"
    },*/
    {
      rango: "Virgulto",
      nome: "Niccolò B.",
      punti: 220,
      alberi: "2",
      da: "10.2021"
    },
    {
      rango: "Virgulto",
      nome: "Simone C.",
      punti: 220,
      alberi: "1",
      da: "01.2022"
    },
    /*{
      rango: "Virgulto",
      nome: "Elisa G.",
      punti: 110,
      alberi: "1",
      da: "11.2021"
    },
    {
      rango: "Virgulto",
      nome: "Andjelika B.",
      punti: 80,
      alberi: "1",
      da: "05.2021"
    },*/
    {
      rango: "Virgulto",
      nome: "Cinzia P.",
      punti: 70,
      alberi: "1",
      da: "09.2022"
    },
    {
      rango: "Virgulto",
      nome: "Lorenza H.",
      punti: 70,
      alberi: "1",
      da: "09.2022"
    },
    {
      rango: "Virgulto",
      nome: "Cinzia B.",
      punti: 70,
      alberi: "1",
      da: "09.2022"
    },
    {
      rango: "Virgulto",
      nome: "Chiara P.",
      punti: 90,
      alberi: "1",
      da: "09.2022"
    },
    {
      rango: "Virgulto",
      nome: "Marta P.",
      punti: 230,
      alberi: "1",
      da: "09.2022"
    },
    {
      rango: "Virgulto",
      nome: "Loris P.",
      punti: 70,
      alberi: "1",
      da: "09.2022"
    },
    {
      rango: "Virgulto",
      nome: "Mattia C.",
      punti: 70,
      alberi: "1",
      da: "09.2022"
    },
    {
      rango: "Virgulto",
      nome: "Chiara L.",
      punti: 70,
      alberi: "1",
      da: "09.2022"
    },
    {
      rango: "Virgulto",
      nome: "Barbara S.",
      punti: 70,
      alberi: "1",
      da: "09.2022"
    },
    {
      rango: "Virgulto",
      nome: "Elidjana R.",
      punti: 70,
      alberi: "1",
      da: "09.2022"
    },
    {
      rango: "Virgulto",
      nome: "Aleksandra L.",
      punti: 90,
      alberi: "1",
      da: "09.2022"
    },
    {
      rango: "Virgulto",
      nome: "Sara M.",
      punti: 90,
      alberi: "1",
      da: "09.2022"
    },
    {
      rango: "Virgulto",
      nome: "Katerina K.",
      punti: 90,
      alberi: "1",
      da: "09.2022"
    }
  ]
  //members: Club[];
  subscription: Subscription;

  constructor(private clubService: ClubService, private dataStorageService: DataStorageService) { }

  ngOnInit(): void {
    /*console.log('ng')
    this.dataStorageService.getMembers();
    this.subscription = this.clubService.clubChanged
      .subscribe(
        (members: Club[]) => {
          this.members = members;*/
          this.members.sort((a, b) => (a.punti > b.punti ? -1 : 1));
        /*}
      );
    this.members = this.clubService.getMembers();
    console.log(this.members)*/
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  add() {
    this.clubService.storeMembers(this.members)
      .subscribe(
        (response) => console.log(response),
        (error) => console.log(error)
      );
  }

}
