
<app-head titolo="Anytime, anywhere"></app-head>

<body>




  <h2>Idea alla base</h2>
  <br>
  <p>L'idea alla base è di rendere le lezioni disponibili sempre, ovunque. </p>
    <p>Basta perdere tempo in scomodi spostamenti! </p>
    <p>Tutto quello che ti serve è aprire il tuo notebook o sbloccare il tuo tablet e sei online, pronto ad imparare. </p>
    <p>Raggiungici sulla rete, raggiungici nel terzo millenio!

  </p>
<br>
  <h2>Come funziona</h2>
  <br>
  <p>Il contatto viene stabilito tramite video chiamata Skype, Whatsapp o un altro provider concordato. Grazie a WhatsappWeb e simili sarà possibile condividere gli esercizi che
    si sta risolvendo, permettendo (ad esempio) di seguire tutti i passaggi e i calcoli in modo pratico e in tempo reale.
  </p>
  <p>2+2=0 e i suoi inseganti hanno svolto migliaia di ore di lezioni online. Il sistema è collaudato e gli allievi si ritengono soddisfatti con una valutazione media di 5.8/6</p>

<br>
  <h2>Costo delle lezioni</h2>
  <br>
  <p> Il costo di questa offerta varia a dipendenza del numero di partecipanti
  </p>
  <br>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">#Allievi</th>
        <th scope="col">Costo</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">1</th>
        <td>29 CHF</td>
      </tr>
      <tr>
        <th scope="row">2</th>
        <td>18 CHF a testa</td>
      </tr>
      <tr>
        <th scope="row">3</th>
        <td>14 CHF a testa</td>
      </tr>
    </tbody>
  </table>
<br>
<!--<h2>Offerta speciale</h2>
<br>
<div class="row">
  <div class="col-md-6 col-sm-12">
    <button class="btn btn-light btn-lg btn-block" id="offer" (click)="open3()"><i class="fas fa-gift fa-3x"></i><p>Pacchetto da 10 lezioni</p><p><del>CHF 250</del> CHF 225</p><p id="sconto">Risparmia CHF25</p></button>
  </div>
  <div class="col-md-6 col-sm-12">
    <button class="btn btn-light btn-lg btn-block" id="offer" (click)="open4()"><i class="fas fa-gift fa-3x"></i><p>Pacchetto da 20 lezioni</p><p><del>CHF 500</del> CHF 450</p><p id="sconto">Risparmia CHF50</p></button>
  </div>
</div>
<br>
<p>Le ore possono essere di materie differenti.</p>
<br>
  <h2>Cosa mi serve per iniziare?</h2>
  <br>
  <p>Tutto ciò che devi fare è scaricare i seguenti programmi (se non li hai già) e creare un account (o accedere).
    Una volta fatto questo, riserva la tua lezione e impara con noi!
  </p>
<br>

  <div class="row" id="pc">
    <div class="col-md-6 col-sm-12">
      <a href="https://evernote.com/intl/it/download/" target="_blank" rel="noopener noreferrer" class="btn btn-light btn-lg btn-block"><img src="../../assets/iconfinder_evernote_132841-min.png" class="figure-img img-fluid" style="width: 60px">Download</a>
    </div>

    <div class="col-md-6 col-sm-12">
      <a href="https://www.skype.com/de/get-skype/" target="_blank" rel="noopener noreferrer" class="btn btn-light btn-lg btn-block"><i class="fab fa-skype fa-3x"></i>Download</a>
    </div>
  </div>

  <div class="container" id="iphone">
    <figure class="figure">
      <img src="../../assets/iconfinder_evernote_132841-min.png" class="figure-img img-fluid" style="width: 45px">
    </figure>Evernote
    <div class="row">
        <div class="col-12">
          <a href="https://apps.apple.com/us/app/evernote/id281796108" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-app-store-ios"></i>Installa</a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <a href="https://play.google.com/store/apps/details?id=com.evernote&hl=de" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-google-play"></i>Installa</a>
        </div>
      </div>
    <i class="fab fa-skype fa-3x"></i>Skype
    <div class="row">
      <div class="col-12">
        <a href="https://apps.apple.com/us/app/skype-for-iphone/id304878510" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-app-store-ios"></i>Installa</a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <a href="https://play.google.com/store/apps/details?id=com.skype.raider&hl=en_US" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-google-play"></i>Installa</a>
      </div>
    </div>
  </div>

  <div class="container" id="ipad">
    <figure class="figure">
      <img src="../../assets/iconfinder_evernote_132841-min.png" class="figure-img img-fluid" style="width: 55px">
    </figure>Evernote
    <div class="row">
        <div class="col-6">
          <a href="https://apps.apple.com/us/app/evernote/id281796108" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-app-store-ios"></i>Installa</a>
        </div>

        <div class="col-6">
          <a href="https://play.google.com/store/apps/details?id=com.evernote&hl=de" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-google-play"></i>Installa</a>
        </div>
      </div>
    <i class="fab fa-skype fa-3x"></i>Skype
    <div class="row">
      <div class="col-6">
        <a href="https://apps.apple.com/us/app/skype-for-ipad/id442012681" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-app-store-ios"></i>Installa</a>
      </div>

      <div class="col-6">
        <a href="https://play.google.com/store/apps/details?id=com.skype.raider&hl=en_US" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-google-play"></i>Installa</a>
      </div>
    </div>
  </div>
  <hr>-->
<br>
  <h2>Iscriviti!</h2>
  <button type="button" class="btn btn-primary btn-lg rounded-pill" (click)="open2()">Iscriviti</button>

<br>
<br>
<!--<h2>I nostri pacchetti scontati</h2>
  <br>
  <p>Stipulando uno dei nostri pacchetti potrete risparmiare su ogni ora di lezione svolta. <small>È richiesto un pagamento anticipato</small></p>
  <br>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">#Ore</th>
        <th scope="col">Nome</th>
        <th scope="col">Costo orario</th>
        <th scope="col">Limite di tempo</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">20</th>
        <td>20 online</td>
        <td>24.50CHF <br class="capo"><span id="meno">-50CHF</span></td>
        <td>2.5 mesi</td>
      </tr>
    </tbody>
  </table>
<br>
<br>-->
  <br>
  <h3>Il tuo feedback è importante!</h3>
  <br>
  <p>Nonostante questo sia un sistema oramai collaudato ti saremmo grati se spendessi 2 minuti del tuo tempo (una volta finita una lezione)
    per valutare lo svolgimento della stessa e darci consigli per migliorare, in modo da poterti offrire la migliore esperienza possibile.
  </p>
  <br>
  <button type="button" class="btn btn-primary btn-lg rounded-pill" (click)="open()" id="valut">Valutazione</button>


</body>
