import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skype-box',
  templateUrl: './skype-box.component.html',
  styleUrls: ['./skype-box.component.css']
})
export class SkypeBoxComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
