<app-head titolo="FAQ:
domande frequenti"></app-head>

<div class="container-fluid">


  <hr>
  <p>

    <button type="button" class="btn btn-outline-link btn-lg btn-block rounded-pill" (click)="isCollapsed1 = !isCollapsed1"
            [attr.aria-expanded]="!isCollapsed1" aria-controls="collapseExample">
            Quando mi viene assegnato un insegnante per la prima volta poi verrà cambiato o rimarrà sempre lo stesso? <!--Vorrei iscrivere mia figlia al corso di prima media ma la prima settimana di agosto siamo via, come possiamo fare?--> <i class="fas fa-chevron-down" [hidden]="!isCollapsed1"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed1"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed1">
    <div class="card">
      <div class="card-body">
       Risposta: Dipende da come ti trovi con lui/lei. Se ti trovi bene puoi semplicemente pianificare le lezioni future direttamente con lui/lei, altrimenti puoi decidere di cambiare e richiedere qualcun altro.<!--Non c'è problema! Contattaceci scrivendoci una email indicandoci le vostre disponibilità. Se ci sono abbastanza ragazzi con lo stesso problema provvederemo ad organizzare un corso extra.-->
      </div>
    </div>
  </div>
  <hr>


  <p>

    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed2 = !isCollapsed2"
            [attr.aria-expanded]="!isCollapsed2" aria-controls="collapseExample">
            Voglio che mio figlio sia seguito nel modo più personale possibile, come faccio? <i class="fas fa-chevron-down" [hidden]="!isCollapsed2"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed2"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed2">
    <div class="card">
      <div class="card-body">
       Risposta: Le lezioni private sono l'ideale in quanto si focalizzano sui bisogni individuali dell'allievo/a.
       Essendo pianificate di volta in volta garantiscono la massima flessibilità.
       Inoltre, serie di esercizi su misura possono essere risolti insieme all'allievo/a. <!--I nostri corsi sono rivolti a un numero molto basso di allievi (da 3 a 6) in modo che ogni allievo possa essere seguito quasi fosse una lezione privata. In più offriamo la possibilità (con un piccolo sovrapprezzo) di richiedere degli esercizi personalizzati su argomenti specifici in cui il ragazzo ha più difficoltà.-->
      </div>
    </div>
  </div>
  <hr>


  <p>
    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed3 = !isCollapsed3"
            [attr.aria-expanded]="!isCollapsed3" aria-controls="collapseExample">
            Le lezioni online sono solo individuali? <!--Non voglio solo un corso di ripasso sull’anno appena finito ma anche di preparazione a quello successivo.--> <i class="fas fa-chevron-down" [hidden]="!isCollapsed3"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed3"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed3">
    <div class="card">
      <div class="card-body">
       Risposta: No, alla stessa lezione possono assistere più ragazzi/e. Il prezzo diminuisce col numero degli allievi che seguono la lezione.<!--Ogni corso è strutturato in questo modo: per esempio il corso “Prima media” offre un ripasso degli argomenti di prima media ma anche una preparazione alla seconda media per poter cominciare al meglio il nuovo anno scolastico.-->
      </div>
    </div>
  </div>
  <hr>




  <p>
    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed4 = !isCollapsed4"
            [attr.aria-expanded]="!isCollapsed4" aria-controls="collapseExample">
            Con un docente è possibile avere ripetizioni di più materie? <!--Mio figlio deve ripetere la seconda media, a quale corso lo devo iscrivere per prepararlo al meglio?--> <i class="fas fa-chevron-down" [hidden]="!isCollapsed4"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed4"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed4">
    <div class="card">
      <div class="card-body">
       Risposta: Sì, generalmente ogni insegnante fa ripetizioni di 2-3 materie differenti, basta chiedere a lui/lei quali altre materie è in grado di spiegare. <!--Va benissimo il corso di seconda media, con la possibilità di richiedere esercizi supplementari in particolare al posto di quelli di terza media per prepararsi meglio alla ripetizione della classe piuttosto che all’anno successivo.-->
      </div>
    </div>
  </div>
  <hr>


  <!--<p>
    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed5 = !isCollapsed5"
            [attr.aria-expanded]="!isCollapsed5" aria-controls="collapseExample">
            In cosa consistono gli esercizi aggiuntivi su misura? <i class="fas fa-chevron-down" [hidden]="!isCollapsed5"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed5"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed5">
    <div class="card">
      <div class="card-body">
       Risposta: A tutti gli studenti verranno forniti numerosi esercizi durante i corsi. Offriamo comunque la possibilità di richiedere degli esercizi (in anticipo o durante i corsi) pensati appositamente per garantire basi solide su argomenti "spinosi".
      </div>
    </div>
  </div>
  <hr>-->

  <p>

    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed6 = !isCollapsed6"
            [attr.aria-expanded]="!isCollapsed6" aria-controls="collapseExample">
            Non c’è nessuna possibilità di avere ripetizioni di una materia non presente nella lista? <!--Quanto costa il corso?--> <i class="fas fa-chevron-down" [hidden]="!isCollapsed6"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed6"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed6">
    <div class="card">
      <div class="card-body">
       Risposta: Al contrario, siamo sempre alla ricerca di nuovi corsi da offrire. Se desideri avere ripetizioni di una materia che non è presente nella lista prova lo stesso a scriverci e vedremo se riusciamo a accontentarti. <!--il corso costa 300 franchi per 20 ore di lezione suddivise in 5 mattine da 4 ore l'una. Nel costo sono compresi anche il materiale per scrivere e un buono sconto del 20% per 5 lezioni individuali a partire da settembre (non solo di matematica).-->
      </div>
    </div>
  </div>
  <hr>


</div>


<div class="container-fluid" id="domanda">

 <h3>Hai altri dubbi o curiosità? Scrivi la tua domanda, risponderemo al più presto</h3>

 <form [hidden]="submitted" (ngSubmit)="onAsked()" #f="ngForm">
  <div class="form" id="ask-data" ngModelGroup="askData" #userData="ngModelGroup">

    <div class="form-group">
        <label for="nome">Nome</label>
        <input type="text" class="form-control" id="nome" placeholder="Nome" ngModel name="nome" required>
    </div>

    <div class="form-group">
      <label for="exampleFormControlInput1">Indirizzo email</label>
      <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="indirizzo email" ngModel name="email" required email #email="ngModel">
    </div>

    <div class="form-group">
      <label for="exampleFormControlTextarea1">Scrivi la tua domanda</label>
      <textarea class="form-control" id="exampleFormControlTextarea1" rows="5" placeholder="Scrivi la tua domanda" ngModel name="text" required></textarea>
    </div>

    <div class="form-group form-check">
         <input type="checkbox" class="form-check-input" id="exampleCheck1" (click)="onSelect()" >
         <label class="form-check-label" for="exampleCheck1">Acconsento all'eventuale pubblicazione della mia domanda in forma anonima per poter aiutare altri utenti (facoltativo)</label>
    </div>

  </div>

  <button type="submit" class="btn btn-primary rounded-pill" [disabled]="!f.valid">Invia</button>

 </form>
 <div [hidden]="!submitted" class="contact-message">
  <div class="contact-success">
    <div class="success">
      <h2>Congratulazioni!</h2>
      <h4>Grazie per averci contattati, ti risponderemo al più presto.</h4>
    </div>
  </div>
</div>
</div>




