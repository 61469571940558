import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
//import { Http } from '@angular/http';

@Injectable()
export class DanteStorageService {
    constructor(private http: HttpClient) {}
    storeUsers(users: any[]) {
        return this.http.post('https://dante-ng-2piu2.firebaseio.com/users.json', users);
    }
}
