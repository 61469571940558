import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Leaflet from 'leaflet';
import { antPath } from 'leaflet-ant-path';
//import { GoogleMap } from '@agm/core/services/google-maps-types';
/*import {
  MapsAPILoader
} from '@agm/core';*/

@Component({
  selector: 'app-dove-siamo',
  templateUrl: './dove-siamo.component.html',
  styleUrls: ['./dove-siamo.component.scss']
})
export class DoveSiamoComponent implements OnInit {
  latitude1 = 46.172761;
  longitude1 = 9.003374;
  location = [
    {
     latitude: 46.172761,
     longitude: 9.003374,
     city: 'Bella'
    },
    {
     latitude: 45.861378,
     longitude: 8.946858,
     city: 'Bella'
    },
    {
      latitude: 46.1745,
      longitude: 9.0015,
      city: 'Bella'
    },
    {
      latitude: 46.1857,
      longitude: 5.9996,
      city: 'Bella'
    },
    {
      latitude: 46.1934,
      longitude: 9.0121,
      city: 'Bella'
    },
    {
      latitude: 46.1895,
      longitude: 9.021,
      city: 'Bella'
    },
    {
      latitude: 46.0076,
      longitude: 8.9549,
      city: 'Bella'
    },
    {
      latitude: 46.1708,
      longitude: 8.7949,
      city: 'Bella'
    }
  ];
  locationChose = true;
  zoom: number = 9;
  //myType = google.maps.MapTypeId.HYBRID;
  map: Leaflet.Map;
  propertyList = [];



  ngOnInit(): void {
    this.map = Leaflet.map('map').setView([46.172761, 9.003374], 9);
    Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'edupala.com © Angular LeafLet',
    }).addTo(this.map);
    fetch('./assets/data/data.json')
      .then(res => res.json())
      .then(data => {
        this.propertyList = data.properties;
        this.leafletMap();
      })
      .catch(err => console.error(err));


    /*antPath([[28.644800, 77.216721], [34.1526, 77.5771]],
      { color: '#FF0000', weight: 5, opacity: 0.6 })
      .addTo(this.map);*/
  }

  leafletMap(): void {
    /*var LeafIcon = Leaflet.Icon.extend({
      options: {
         iconSize:     [38, 95],
         iconAnchor:   [22, 94],
         popupAnchor:  [-3, -76]
      }
    });
    var greenIcon = new LeafIcon({
      iconUrl: "../../assets/map-marker-alt-solid.svg",
    })*/

  const icon = Leaflet.divIcon({
    className: "my-custom-pin",
    iconAnchor: [0, 24],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: `<i class="fas fa-map-marker-alt fa-2x" style="color:red"></i>`
  })
    for (const property of this.location) {
      Leaflet.marker([property.latitude, property.longitude], {icon: icon}).addTo(this.map)
        /*.bindPopup(property.city)
        .openPopup()*/;
    }
  }

  ngOnDestroy(): void {
    this.map.remove();
  }

}




