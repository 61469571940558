import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule } from '@angular/router';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


/*Import components*/
import { AppNavComponent } from './app-nav/app-nav.component';
import { MainboxComponent } from './mainbox/mainbox.component';
import { BoxLeftComponent } from './box-left/box-left.component';
import { BoxRightComponent } from './box-right/box-right.component';
import { ChiSiamoComponent } from './chi-siamo/chi-siamo.component';
import { DoveSiamoComponent } from './dove-siamo/dove-siamo.component';
import { HomeComponent, NgbdModalPubb } from './home/home.component';
import { FAQComponent } from './faq/faq.component';
import { ChiUnoComponent } from './chi-1/chi-uno.component';
import { ChiDueComponent } from './chi-2/chi-due.component';
import { ChiTreComponent } from './chi-3/chi-tre.component';
import { BoxBottomComponent } from './box-bottom/box-bottom.component';
import { DanteStorageService } from './corsi-young/iscriviti/dante-storage.service';
import { AskStorageService } from './faq/ask-storage.service';
import { AltoBoxComponent } from './alto-box/alto-box.component';
import { ZFooterComponent } from './z-footer/z-footer.component';
import { MasterComponent, NgbdModalMaster } from './master/master.component';
import { MasterBoxComponent } from './master-box/master-box.component';
import { SkypeComponent, NgbdModalSkype, NgbdModalOnline } from './skype/skype.component';
import { SkypeBoxComponent } from './skype-box/skype-box.component';
import { InfoComponent } from './info/info.component';
import { InfoBoxComponent } from './info-box/info-box.component';
import { JoinBoxComponent } from './join-box/join-box.component';
import { PrivatoComponent, NgbdModalPrivato, NgbdModalRecensione } from './privato/privato.component';
import { PrivatoBoxComponent } from './privato-box/privato-box.component';
import { DropdownDirective } from './shared/dropdown.directive';
import { DataStorageService } from './shared/data-storage.service';
import { JoinStorageService } from './chi-siamo/join-storage.service';
import { ValutazioneStorageService } from './skype/valutazione-storage.service';
import { TreedomComponent } from './treedom/treedom.component';
import { TreedomBoxComponent } from './treedom-box/treedom-box.component';
import { OnlineBoxComponent } from './online-box/online-box.component';
import { OnlineComponent, NgbdModalPaypal, NgbdModalTwint, NgbdModalQuizlet } from './online/online.component';
import { ContattiComponent, NgbdModalContent } from './contatti/contatti.component';
import { RecensioniComponent } from './recensioni/recensioni.component';
import { SociComponent } from './soci/soci.component';
import { MaterieComponent } from './materie/materie.component';
import { InsegnantiComponent } from './insegnanti/insegnanti.component';
import { TeacherComponent } from './teacher/teacher.component';
/*Fine import components*/
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { AgmCoreModule } from '@agm/core';
import { ClubComponent } from './club/club.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { ClubService } from './club/club.service';
import { SharedModule } from './shared/shared.module';
import { QRComponent } from './qr/qr.component';

@NgModule({
  declarations: [
    AppComponent,
    AppNavComponent,
    MainboxComponent,
    BoxLeftComponent,
    BoxRightComponent,
    ChiSiamoComponent,
    DoveSiamoComponent,
    HomeComponent,
    FAQComponent,
    ChiUnoComponent,
    ChiDueComponent,
    ChiTreComponent,
    BoxBottomComponent,
    AltoBoxComponent,
    ZFooterComponent,
    MasterComponent,
    MasterBoxComponent,
    SkypeComponent,
    SkypeBoxComponent,
    InfoComponent,
    InfoBoxComponent,
    JoinBoxComponent,
    PrivatoComponent,
    PrivatoBoxComponent,
    NgbdModalContent,
    NgbdModalSkype,
    DropdownDirective,
    NgbdModalPrivato,
    NgbdModalMaster,
    NgbdModalOnline,
    NgbdModalRecensione,
    TreedomComponent,
    TreedomBoxComponent,
    OnlineBoxComponent,
    OnlineComponent,
    NgbdModalPaypal,
    NgbdModalTwint,
    NgbdModalQuizlet,
    NgbdModalPubb,
    ContattiComponent,
    RecensioniComponent,
    SociComponent,
    MaterieComponent,
    InsegnantiComponent,
    TeacherComponent,
    ClubComponent,
    WhatsappComponent,
    QRComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FontAwesomeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBL9SOsaxjOqMjQXnpQddxRWELxzNB8XCY',
      libraries: ['places']
    }),
    NgbModule,
    HttpClientModule,
    NgbModalModule,
    MaterialModule,
    SharedModule
  ],
  providers: [DanteStorageService, FAQComponent, AskStorageService, DataStorageService, JoinStorageService, ValutazioneStorageService, ClubService],
  bootstrap: [AppComponent],
  entryComponents: [NgbdModalContent, NgbdModalSkype, NgbdModalPrivato, NgbdModalMaster, NgbdModalOnline, NgbdModalRecensione, NgbdModalPaypal, NgbdModalTwint, NgbdModalQuizlet, NgbdModalPubb],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
