
<header>
    <meta name="Description" content="2+2=0. Offre corsi su misura a Giubiasco e Bellinzona viale stazione 19 palestra alphaboulder.">
  <div class="container-fluid" id="DoveHeader">

  <h1>
    Corsi di formazione
  </h1>

  </div>
</header>
<body>

<h2>A chi sono rivolti i nostri corsi di formazione?</h2>
<p>A chiunque sia interessato a imparare, migliorarsi , siano essi ragazzi adulti o anziani. I nostri corsi sono aperti a tutte le età.</p>

<h4>Le nostre offerte formative:</h4>
<p>Sfoglia tra le nostre offerte formative e scegli il corso che più si adatta alle tue esigenze.</p>
<!--<ngb-carousel>
  #mycarousel
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="../../assets/employee-3005501_1920-min.jpg" alt="Random first slide">
    </div>
    <div class="carousel-caption">
      <h3 style="background-color: rgba(228, 228, 228, 0.7); color: black;">Pacchetto Office</h3>
      <p style="background-color: rgba(228, 228, 228, 0.7); color: black;">Corso per principianti. Impara le basi </p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="../../assets/latex-97866_1280-min.png" alt="Random first slide">
    </div>
    <div class="carousel-caption">
      <h3 style="background-color: rgba(228, 228, 228, 0.7); color: black;">Corso di LaTex</h3>
      <p style="background-color: rgba(228, 228, 228, 0.7); color: black;">Corso per principianti. Impara le basi di uno dei più famosi programmi per testi scientifici. Usato in tutte le facoltà scientifiche</p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <img src="../../assets/arduino-2168193_1920-min.png" alt="Random first slide">
      </div>
      <div class="carousel-caption">
        <h3 style="background-color: rgba(228, 228, 228, 0.7); color: black;">Arduino</h3>
        <p style="background-color: rgba(228, 228, 228, 0.7); color: black;">Boh</p>
      </div>
    </ng-template>
</ngb-carousel>-->
<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="card" *ngFor="let c of corsi">
      <img src="{{ c.img }}" class="card-img-top" alt="...">
      <div class="card-body">
        <h5 class="card-title">{{ c.h }}</h5>
        <p class="card-text">{{ c.p }}</p>
        <div class="card-footer">
          <small class="text-muted">{{ c.s }}</small>
        </div>
      </div>
    </div>
  </div>
</div>


<h4>Quando si svolgono i corsi?</h4>
<p>I corsi si svolgono il week end, generalmente il sabato mattina.</p>
</body>
