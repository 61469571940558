<app-head titolo="CONTATTI"></app-head>
<body>
  <div class="col-12" >
    <div class="col-12" >
    <div class="container-fluid">
    <h1>I nostri contatti:</h1>
    <br>
    <br>
    <br>
    <app-soci></app-soci>
      <!--<div class="row" id="contatti">
        <div class="col-12">
          <div class="row" id="nomi">
          <p id="nome">Michele Piller</p>
          <i class="fas fa-phone">&nbsp;+41 78 679 97 80</i>
          </div>
        </div>
        <div class="col-12">
          <div class="row" id="nomi">
          <p id="nome">Gabriele Moro</p>
          <i class="fas fa-phone">&nbsp;+41 78 696 03 29</i>
          </div>
        </div>-->
      <br>
      <br>
      <br>
      <div class="row" id="contatti">
        <div class="col-md-4 col-xs-12" id="cont">
          <a href="https://www.facebook.com/220-569467980247217" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-square" id="fb"></i> &nbsp;2+2=0</a>
        </div>
        <div class="col-md-4 col-xs-12">
          <a href="https://www.instagram.com/2piu2uguale_0/" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram"></i>&nbsp;2piu2uguale_0</a>
        </div>
        <div class="col-md-4 col-xs-12">
          <a href="mailto:2piu2uguale0@gmail.com"><i class="fas fa-envelope"></i>&nbsp;2piu2uguale0@gmail.com</a>
        </div>
       </div>
    </div>
  </div>
</div>
<br>
<br>
<br>


  <h2 id="join">Join Us!</h2>
<br>
<!--<p>La nostra community conta già una trentina di insegnanti, ma siamo sempre alla ricerca di nuovi interessati che possano aiutarci a coprire la richiesta di lezioni per tutte le varie materie scolastiche che riceviamo.</p>-->
<p>Sei uno studente universitario con una particolare passione e interesse per una materia?<br>
Sei motivato a condividere le tue conoscenze con studenti più giovani?<br>
Stai cercando un'occupazione divertente e stimolante che ti permetta anche di guadagnare qualche soldo?</p>
<p>Noi ti offriamo tutto questo!</p>
<button type="button" class="btn btn-primary btn-lg rounded-pill" (click)="open()">Join us!</button>
</body>
