import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chi-uno',
  templateUrl: './chi-uno.component.html',
  styleUrls: ['./chi-uno.component.css']
})
export class ChiUnoComponent implements OnInit {
  public isCollapsed = true;
  constructor() { }

  ngOnInit() {
  }

}



