<body>
<div class="container-fluid" id="sfondo">
  <div class="col-12" >
      <div class="col-12" >
      <div class="container-fluid">
      <h4>Contatti:</h4>
        <div class="row" id="contatti">
          <div class="col-md-2 col-xs-12">
            <a href="https://www.facebook.com/220-569467980247217" target="_blank" rel="noopener noreferrer"><!--<fa-icon [icon]="faFacebook" id="fb"> &nbsp;</fa-icon>--><i class="fab fa-facebook-square"></i>&nbsp;2+2=0</a>
          </div>
          <div class="col-md-2 col-xs-12">
            <a href="https://www.instagram.com/2piu2uguale_0/" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram"></i>&nbsp;2piu2uguale_0</a>
          </div>
          <div class="col-md-4 col-xs-12">
            <a href="mailto:2piu2uguale0@gmail.com"><fa-icon [icon]="faEnvelope"></fa-icon>2piu2uguale0@gmail.com</a>
          </div>
          <div class="col-md-4 col-xs-12">
            <fa-icon [icon]="faPhone"></fa-icon>079 566 64 66<!--<br><span id="tel">079 610 84 52</span>--><!--<br><span id="tel">078 696 03 29</span>-->
          </div>
         </div>
      </div>
    </div>
  </div>
    <div class="container-fluid" id="tasto">
      <!--<a routerLink="/FAQ" class="btn btn-light btn-outline-secondary btn-lg btn-block border-dark rounded-pill" role="button" aria-pressed="true" id="bottone" >CONTATTACI</a>-->
      <button routerLink="/FAQ" class="btn btn-lg btn-block rounded-pill">CONTATTACI</button>
    </div>

</div>
</body>


