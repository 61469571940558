import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chi-due',
  templateUrl: './chi-due.component.html',
  styleUrls: ['./chi-due.component.css']
})
export class ChiDueComponent implements OnInit {
  public isCollapsed = true;
  constructor() { }
  Nascosto = true;
  onMostra() {
  this.Nascosto=!this.Nascosto ;
  
  }

  ngOnInit() {
  }

}
