import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ChiSiamoComponent } from './chi-siamo/chi-siamo.component';
import { ClubComponent } from './club/club.component';
import { ContattiComponent } from './contatti/contatti.component';
//import { DoveSiamoComponent } from './dove-siamo/dove-siamo.component';
import { FAQComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { InfoComponent } from './info/info.component';
//import { InsegnantiComponent } from './insegnanti/insegnanti.component';
import { MasterComponent } from './master/master.component';
//import { OnlineComponent } from './online/online.component';
import { PrivatoComponent } from './privato/privato.component';
import { SkypeComponent } from './skype/skype.component';
import { TreedomComponent } from './treedom/treedom.component';
import { QRComponent } from './qr/qr.component';

const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'ChiSiamo', component: ChiSiamoComponent},
  //{ path: 'DoveSiamo', component: DoveSiamoComponent},
  { path: 'FAQ', component: FAQComponent},
  { path: 'Formazione', component: InfoComponent},
  { path: 'Master', component: MasterComponent},
  { path: 'Skype', component: SkypeComponent},
  { path: 'Privato', component: PrivatoComponent},
  { path: 'Ecologia', component: TreedomComponent},
  {path: 'QR', component: QRComponent},
  //{ path: 'Online', component: OnlineComponent},
  { path: 'Contatti', component: ContattiComponent },
  //{ path: 'Insegnanti', component: InsegnantiComponent },
  { path: 'Club', component: ClubComponent},
  /*{ path: 'CorsiRagazzi',
      loadChildren: () => import('./corsi-young/corsi-young.module').then(m => m.CorsiYoungModule)},
  { path: 'CorsiAdulti',
      loadChildren: () => import('./corsi-old/corsi-old.module').then(m => m.CorsiOldModule)},*/
  {path: '404', component: HomeComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
