<app-head titolo="CHI SIAMO"></app-head>


<body>
 <div class="container-fluid" id="Collassato">

  <app-chi-uno></app-chi-uno>
  <hr>
 <!--<app-chi-due></app-chi-due>
 <hr>
 <app-chi-tre></app-chi-tre>-->
 <hr>

 </div>

 <div class="container-fluid" id="NonCollassato">

  <h2>Cos'è 2+2=0?</h2>
<br>
  <p>2+2=0 è un servizio di supporto allo studio che mette in contatto allievi che hanno bisogno di lezioni di recupero con studenti universitari con la passione per l'insegnamento.
    Da anni offriamo aiuto a ragazzi di scuole medie, scuole post-obbligatorie (superiori, professionali, ...), apprendisti e professionisti.</p>
  <hr>
 </div>
<br>
<br>
<br>
<h3>Diamo i numeri</h3>
<br>
<div class="row">
  <div class="col-12 col-lg-6">
    Allievi aiutati: {{allievi}}<br> <fa-icon id="icona" [icon]="faUsers"></fa-icon>
  </div>
  <div class="col-12 col-lg-6">
    Ore di lezione svolte: {{ore}}<br> <fa-icon [icon]="faChalkboardUser"></fa-icon>
  </div>
</div>
<!--<div style="margin-left:25%;font-size:larger" class="row">
  <p>I nostri insegnanti hanno svolto più di</p> <h5 style="color:red;margin-left:1%;margin-right:1%;padding-top:0.2%;" class="animate__animated animate__heartBeat animate__infinite">{{ore}}</h5> <p>ore di lezione aiutando</p> <h5 style="color:red;margin-left:1%;margin-right:1%;padding-top:0.2%;" class="animate__animated animate__heartBeat animate__infinite">{{allievi}}</h5> <p>allievi!</p>
</div>-->
<br>
<br>
<br>

<h3>Dicono di noi</h3>
<br>
<br>
<app-recensioni></app-recensioni>
<br>
<br>
<br>

<h3>Che materie offriamo</h3>
<br>
<br>
<app-materie></app-materie>
<br>
<br>
<br>
<!--<h3>Scopri i nostri insegnanti</h3>
<br>
<br>
<button type="button" class="btn btn-primary btn-lg rounded-pill" id="insegnanti" routerLink="/Insegnanti">I nostri insegnanti</button>-->
</body>
