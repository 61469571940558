<app-head titolo="DOVE SIAMO"></app-head>

<body>
  <div class="container-fluid">

    <br>
    <br>
    <h4 style="text-align: center">I nostri insegnanti coprono tutto il territorio per garantire un insegnamento di prossimità</h4>
    <br>
    <br>
    <!--<h2>Ci trovi in Viale Stazione 19, Giubiasco <br> Presso la palestra Alphaboulder <br><a href="https://www.alphaboulder.ch/" target="_blank"><img src="assets/logo_alphaboulder.png" alt=""></a></h2>-->
    <div class="row">
    <!--<div class="col-12" id="mappa">
      <agm-map [latitude]="latitude1" [longitude]="longitude1" [zoom]="zoom" [mapTypeControl]='true'>
        <agm-marker *ngFor="let loc of location" [latitude]="loc.latitude" [longitude]="loc.longitude"></agm-marker>
      </agm-map>
    </div>-->

    <div id="map"></div>

    <!--<agm-map
   [latitude]='latitude1'
   [longitude]='longitude1'
   >

    <agm-marker
        [latitude]="latitude1"
        [longitude]="longitude1">
    </agm-marker>

</agm-map>-->


    </div>
  </div>

</body>


