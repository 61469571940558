<p>

    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
      Cos'è 2+2=0? <i class="fas fa-chevron-down" [hidden]="!isCollapsed"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-body">
        2+2=0 è un servizio di supporto allo studio che mette in contatto allievi che hanno bisogno di lezioni di recupero con studenti universitari con la passione per l'insegnamento.
        Da anni offriamo aiuto a ragazzi di scuole medie, scuole post-obbligatorie (superiori, professionali, ...), apprendisti e professionisti.
      </div>
    </div>
  </div>
