import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngdb-modal-paypal',
  template: `
  <div class="modal-header">
    <h4 class="modal-title">Modalità</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
  <p>Le offerte su PayPal possono essere spedite alla mail</p> <b>2piu2uguale0@gmail.com,</b>
  <p>mandando l'importo desiderato
    indicando "Smart Teaching" nel giustificativo.
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
  `
})

export class NgbdModalPaypal {
  constructor(public activeModal: NgbActiveModal) {}

}

@Component({
  selector: 'ngdb-modal-twint',
  template: `
  <div class="modal-header">
    <h4 class="modal-title">Modalità</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
  <p>Le offerte su TWINT si possono mandare al numero</p> <b>+41 78 679 97 80,</b>
  <p>inserendo l'importo desiderato,
    e indicando "Smart Teaching" nel messaggio giustificativo.
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
  `
})

export class NgbdModalTwint {
  constructor(public activeModal: NgbActiveModal) {}

}

@Component({
  selector: 'ngdb-modal-quizlet',
  template: `
  <div class="modal-header">
    <h4 class="modal-title">Iscriviti alle nostre classi</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
  <table class="table table-borderless">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Medie</th>
      <th scope="col">Liceo</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td><a href="https://quizlet.com/join/mrx9a9VgC" target="_blank" rel="noopener noreferrer" class="btn btn-light btn-lg btn-block">Seconda Media</a></td>
      <td><a href="https://quizlet.com/join/5NSKSh9ag" target="_blank" rel="noopener noreferrer" class="btn btn-light btn-lg btn-block">Prima Liceo</a></td>
    </tr>
  </tbody>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td><a href="https://quizlet.com/join/zWY5dkb4q" target="_blank" rel="noopener noreferrer" class="btn btn-light btn-lg btn-block">Terza Media</a></td>
      <td></td>
    </tr>
  </tbody>
</table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
  `
})

export class NgbdModalQuizlet {
  constructor(public activeModal: NgbActiveModal) {}

}

@Component({
  selector: 'app-online',
  templateUrl: './online.component.html',
  styleUrls: ['./online.component.scss']
})
export class OnlineComponent implements OnInit {

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
  }

  onOpenPay() {
    const modalRef = this.modalService.open(NgbdModalPaypal);
  }

  onOpenTwint() {
    const modalRef = this.modalService.open(NgbdModalTwint);
  }

  onOpenQuizlet() {
    const modalRef = this.modalService.open(NgbdModalQuizlet);
  }
}
