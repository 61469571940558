import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../environments/environment';

//const BACKEND_URL = environment.apiUrl + '/mail/';
//const BACKEND_URL = 'http://localhost:3000/mail';

@Injectable({ providedIn: 'root'})
export class MailService {
  dati = [];

  constructor(private http: HttpClient, private router: Router) {}

  sendMail(url, dati) {
    this.http.post(url, dati)
    .subscribe(
      data => {
          console.log("POST Request is successful ", data);
      },
      error => {
          console.log("Error", error);
      }
    );
  }
}
