import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soci',
  templateUrl: './soci.component.html',
  styleUrls: ['./soci.component.scss']
})
export class SociComponent implements OnInit {

  soci = [
    /*{
      img: "../../assets/pic6.png",
      nome: "Michele Piller",
      studi: "Studente di Master in Datascience for Astrophysics",
      city: "Monte Carasso",
      telefono: "+41 78 679 97 80",
      responsabile: "corsi di gruppo",
      email: "corsi@2piu2uguale0.ch",
      orient: 0
    },*/
    {
      img: "../../assets/gabo.jpeg",
      nome: "Gabriele Moro",
      studi: "Master in Banking and Finance",
      city: "Giubiasco",
      telefono: "+41 78 696 03 29",
      responsabile: "Presidente",
      email: "amministrazione@2piu2uguale0.ch",
      orient: 1
    },
    {
      img: "../../assets/Debora.jpeg",
      nome: "Debora Fässler",
      studi: "Master in Relazioni Internazionali",
      city: "Vezia",
      telefono: "+41 79 566 64 66",
      responsabile: "Vicepresidente",
      email: "lezioni@2piu2uguale0.ch",
      orient: 0
    }/*,
    {
      img: "../../assets/Chiara.jpeg",
      nome: "Chiara Balestra",
      studi: "Studentessa di master in diritti del bambino",
      city: "Massagno",
      telefono: "+41 79 610 84 52",
      responsabile: "corsi Luganese",
      email: "lezioni@2piu2uguale0.ch",
      orient: 1
    },
    {
      img: "../../assets/Barbara.jpeg",
      nome: "Barbara Sala",
      studi: "Studentessa di bachelor in psicologia",
      city: "Ascona",
      telefono: "+41 77 419 38 29",
      responsabile: "corsi Locarnese",
      email: "lezioni@2piu2uguale0.ch",
      orient: 0
    },*/
  ];

  /*stagista = [
    {
      img: "../../assets/sabina.png",
      nome: "Sabina Pedrazzini",
      studi: "Studente di Master in Psicologia Applicata",
      city: "Monte Carasso",
      telefono: "+41 77 431 20 26",
      orient: 0
    },
  ];*/
  constructor() { }

  ngOnInit() {
  }

}
