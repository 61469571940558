import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Club } from '../club/club.model';
import { ClubService } from '../club/club.service';
import { environment } from '../../environments/environment';

//import { Http } from '@angular/http';

@Injectable({ providedIn: 'root' })
export class DataStorageService {
    constructor(private http: HttpClient, private clubService: ClubService) {}
    storeUsers(iscritti: any[]) {
        return this.http.post('https://dante-ng-2piu2.firebaseio.com/iscritti.json', iscritti);
    }

    storeRecens(recensioni: any[]) {
      return this.http.post('https://dante-ng-2piu2.firebaseio.com/recensioni.json', recensioni);
    }

    getMembers() {
      return this.http.get<Club[]>('https://dante-ng-2piu2.firebaseio.com/membri.json')
      .pipe(
        map(members => {
          console.log(members)
          return members.map<Club>(members => {
            return {
              ...members
            };
          });
        })
      )
      .subscribe(members => {
        this.clubService.setMembers(members);
      });
    }

    getHours() {
      return this.http.get<any>('https://dante-ng-2piu2.firebaseio.com/Hours.json');
    }
    getHoursBE() {
      return this.http.get<number>(`${environment.apiUrl}lezioni/1/totale_ore/`);
    }

    getNRAllievi() {
      return this.http.get<number>(`${environment.apiUrl}allievi/1/GetNRAllievi/`);
    }
}
