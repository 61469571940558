import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  corsi = [
    {img: '../../assets/employee-3005501_1920-min.jpg',
     h: 'Pacchetto Office',
     p: 'Corso per principianti, impara le basi',
     s: 'Sabato mattina'},
     {img: '../../assets/latex-97866_1280-min.png',
     h: 'Corso LaTex',
     p: 'Corso per principianti, impara le basi',
     s: 'Sabato mattina'},
     {img: '../../assets/arduino-2168193_1920-min.png',
     h: 'Arduino',
     p: 'Corso per principianti, impara le basi',
     s: 'Sabato mattina'},
     {img: '../../assets/arduino-2168193_1920-min.png',
     h: 'Matlab',
     p: 'Corso per principianti, impara le basi',
     s: 'Sabato mattina'},
     {img: '../../assets/arduino-2168193_1920-min.png',
     h: 'Python',
     p: 'Corso per principianti, impara le basi',
     s: 'Sabato mattina'},
  ]
}
