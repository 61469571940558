import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chi-tre',
  templateUrl: './chi-tre.component.html',
  styleUrls: ['./chi-tre.component.css']
})
export class ChiTreComponent implements OnInit {
  public isCollapsed = true;
  constructor() { }

  Nascosto = true;
  onMostra() {
  this.Nascosto=!this.Nascosto ;
  }


  ngOnInit() {
  }

}
