


<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <a class="navbar-brand" href="#">
        <img src="assets/2piu2.png" width="30" height="30" class="d-inline-block align-top" alt="">
        2+2=0
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#caccapupu" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleCollapsed()">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!--<div class="collapse navbar-collapse" id="caccapupu">-->
      <div id="caccapupu" [ngClass]="{'collapse':collapsed, 'navbar-collapse': true}">

      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" routerLinkActive="active" routerLink="/" (click)="toggleCollapsed()">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/ChiSiamo" (click)="toggleCollapsed()">Chi siamo</a>
        </li>
        <!--<li ngbDropdown class="dropdown">
        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" ngbDropdownToggle (click)="onEspandi()">
          Corsi <span class="caret"></span>
        </a>
        <div ngbDropdownMenu class="dropdown-menu">
          <li><a class="dropdown-item" routerLinkActive="active" routerLink="/CorsiRagazzi">Corsi ragazzi</a></li>
          <li><a class="dropdown-item" routerLinkActive="active" routerLink="/CorsiAdulti">Corsi adulti</a></li>
        </div>
      </li>-->
        <!--<li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/Iscriviti" (click)="toggleCollapsed()" style="color: deepskyblue;">Corsi Estivi</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/CorsiCarnevale" (click)="toggleCollapsed()" style="color: deepskyblue;">Corsi di Carnevale</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/CorsoMatura" (click)="toggleCollapsed()" style="color: deepskyblue;">Corsi Maturità</a>
        </li>-->
        <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/Privato" (click)="toggleCollapsed()">Lezioni private</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/Skype" (click)="toggleCollapsed()">Lezioni online</a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/Insegnanti" (click)="toggleCollapsed()">Insegnanti</a>
        </li>-->
        <li class="nav-item">
          <a class="nav-link" id="eco" routerLinkActive="active" routerLink="/Ecologia" (click)="toggleCollapsed()">Ecologia</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/Club" (click)="toggleCollapsed()">3-Club</a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/Online" (click)="toggleCollapsed()">Smart Teaching</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/Master" (click)="toggleCollapsed()">Master classes</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/Formazione" (click)="toggleCollapsed()">Corsi di formazione</a>
        </li>-->
        <!--<li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/DoveSiamo" (click)="toggleCollapsed()">Dove siamo</a>
        </li>-->
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/FAQ" (click)="toggleCollapsed()">FAQ</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/Contatti" (click)="toggleCollapsed()">Contatti</a>
        </li>

      </ul>

    </div>
  </nav>





















