import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { JoinStorageService } from '../chi-siamo/join-storage.service';
import { MailService } from '../shared/mail.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'ngdb-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Join us!</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [hidden]="submitted" (ngSubmit)="onJoin()" #f="ngForm">
        <div class="form" id="join-data" ngModelGroup="joinData" #userData="ngModelGroup">
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" ngModel name="email" required email #email="ngModel">
          </div>
          <div class="form-group">
            <label for="nome">Nome</label>
            <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Nome" ngModel name="nome" required>
          </div>
          <div class="form-group">
            <label for="cognome">Cognome</label>
            <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Cognome" ngModel name="cognome" required>
          </div>
          <div class="form-group">
            <label for="cognome">Che cosa studi?</label>
            <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Che cosa studi" ngModel name="corsouni" required>
          </div>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Cosa saresti disposto a insegnare?</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Fai una lista delle materie" rows="8" ngModel name="text"></textarea>
          </div>
          <button type="submit" class="btn btn-primary" [disabled]="!f.valid">Invia</button>
        </div>
      </form>
      <div [hidden]="!submitted" class="contact-message">
        <div class="contact-success">
          <div class="success">
            <h2>Congratulazioni!</h2>
            <h4>Grazie per averci contattati, ti risponderemo al più presto.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `

})
export class NgbdModalContent {
  @ViewChild('f', {static : true}) signupForm: NgForm;
  BACKEND_URL = environment.apiUrl + 'contact/1/send_mail_join/';//'/mail/insegnanti';

  joinform2 = {
    nome: '',
    cognome: '',
    email: '',
    corsouni: '',
    materie: '',
  };
  joinform = [
    {
      nome: 'prova',
      cognome: 'prova',
      email: 'prova',
      corsouni: 'prova',
      materie: 'prova'
    }
  ];
  nome = '';
  cognome = '';
  email = '';
  corsouni = '';
  materie = '';
  constructor(public activeModal: NgbActiveModal, private joinStorageService: JoinStorageService, private mailService: MailService) {}
  submitted = false;

  onJoin() {
    this.submitted = true;
    this.joinform.push({
    email: this.signupForm.value.joinData.email,
    nome: this.signupForm.value.joinData.nome,
    cognome: this.signupForm.value.joinData.cognome,
    corsouni: this.signupForm.value.joinData.corsouni,
    materie: this.signupForm.value.joinData.text
  });

  this.joinform2.email = this.signupForm.value.joinData.email,
  this.joinform2.nome = this.signupForm.value.joinData.nome,
  this.joinform2.cognome = this.signupForm.value.joinData.cognome,
  this.joinform2.corsouni = this.signupForm.value.joinData.corsouni,
  this.joinform2.materie = this.signupForm.value.joinData.text

  this.mailService.sendMail(this.BACKEND_URL, this.joinform2);

  this.joinStorageService.storeJoin(this.joinform)
    .subscribe(
      (response) => console.log(response),
      (error) => console.log(error)
    );
  this.signupForm.reset();
  }
}

@Component({
  selector: 'app-contatti',
  templateUrl: './contatti.component.html',
  styleUrls: ['./contatti.component.scss']
})
export class ContattiComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  open() {
    const modalRef = this.modalService.open(NgbdModalContent);
  }
}
