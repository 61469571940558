<p>
    
    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
            Perchè c'è un massimo di partecipanti? <i class="fas fa-chevron-down" [hidden]="!isCollapsed"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-body">
          <p>Classi piccole = massima attenzione per ognuno</p>
          <button type="button" class="btn btn-link btn-xs" (click)="onMostra()" [hidden]="!Nascosto" id="link">Mostra altro...</button>
          <p id="info" [hidden]="Nascosto">Le nostre classi sono composte da al massimo 6 studenti per poter adeguare le lezioni ai bisogni INDIVIDUALI e garantire la massima attenzione possibile.</p>
          <p id="info" [hidden]="Nascosto">Offriamo come servizio EXTRA la possibilità di ordinare in anticipo ULTERIORI esercizi personalizzati dove lo studente ha più bisogno.</p>
          <button type="button" class="btn btn-link btn-xs" (click)="onMostra()" [hidden]="Nascosto" id="link">Mostra meno...</button>
      </div>
    </div>
  </div>