import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mainbox',
  templateUrl: './mainbox.component.html',
  styleUrls: ['./mainbox.component.min.css']
})
export class MainboxComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
