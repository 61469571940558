import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QRComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.location.href = "https://www.treedom.net/it/organization/anonymous_ab666b00";
  }

}
