import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.scss']
})
export class TeacherComponent implements OnInit {
  orientamento: number;


  insegnanti = [
    /*{
      img: "../../assets/dennis.png",
      nome: "Dennis",
      studi: "Ingegneria meccanica al Politecnico di Zurigo",
      durata: "Do ripetizioni da un anno",
      materia: "Insegno mate, fisica, chimica e inglese",
      city: "Insegno nei dintorni di Locarno e online :)",
      hobby: "Nel tempo libero mi piace cucinare e fare sport. ",
      cosa: "2+2=0 per me è una soluzione che permette a giovani allievi di trovare insegnanti motivati e adatti alle loro esigenze",
      motivazione: "Mi piace rendere le nozioni interessanti e rivelare come in realtà spesso possano rivelarsi facili",
      cit: "Solo chi si arrende ha perso",
      orient: 0
    },*/
    {
      img: "../../assets/katerina.jpeg",
      nome: "Katerina",
      studi: "Master in Filosofia e Lettere all’Università di Zurigo",
      durata: "Do ripetizioni da un anno",
      materia: "Insegno tedesco, italiano, spagnolo e inglese ",
      city: "Insegno a Bellinzona e online",
      hobby: "La mia passione più grande è viaggiare e scoprire varie culture",
      cosa: "2+2=0 per me è un’opportunità che mi permette di applicare nella pratica le mie conoscenze svolgendo delle lezioni con i ragazzi delle medie, ma anche del liceo ed altre scuole superiori.",
      motivazione: "Mi piace creare un’atmosfera dinamica e di divertimento con lo scopo di aiutare gli studenti ad imparare le lingue nel modo più semplice",
      cit: "Taci per ascoltare, ascolta per ricordare, ricorda per agire, agisci ma non dimenticarti di continuare a studiare, perché la saggezza è l’unica arma in grado di combattere l’ignoranza – Proverbio Arabo",
      orient: 1
    },
    {
      img: "../../assets/sofia.JPEG",
      nome: "Sofia",
      studi: "Studio architettura a Coira",
      durata: "Insegno tedesco con 2+2=0 da Aprile 2020",
      materia: "Insegno tedesco",
      city: "Insegno principalmente a Bellinzona, spesso anche online",
      hobby: "Mi piace molto andare in montagna nel tempo libero",
      cosa: "2+2=0 è un'ottima opportunità di lavoro durante gli studi, flessibile e soddisfacente!",
      motivazione: "Mi piace aiutare gli allievi a superare gli ostacoli e a capire ciò che sembra troppo complicato ad un primo sguardo. Mi dà molta soddisfazione sentirli più sicuri delle proprie capacità alla fine delle lezioni, e vederli raggiungere buoni risultati.",
      cit: "'Gli uomini, mentre insegnano, imparano.' - Seneca",
      orient: 0
    },
    {
      img: "../../assets/pamela.png",
      nome: "Pamela",
      studi: "Scienze economiche presso l’Università della Svizzera Italiana",
      durata: "Insegno da aprile 2020, da quando è iniziata la mia esperienza con 2+2=0",
      materia: "Insegno inglese, francese e tedesco ",
      city: "Insegno principalmente online ma sono disponibile anche a Lugano",
      hobby: "Adoro fare lunghe passeggiate, anche in montagna, prepare dolci e leggere romanzi",
      cosa: "2+2=0 per me è un occasione per mettermi alla prova e migliorare le mie abilità di insegnamento in un ambiente giovanile e dinamico",
      motivazione: "Ciò che mi spinge a voler insegnare è la mia volontà di aiutare gli altri a sviluppare le proprie capacità di comprensione e apprendimento della materia in questione",
      cit: "'L’istruzione è l’arma più potente che possiamo usare per cambiare il mondo.' Nelson Mandela",
      orient: 1
    },
    {
      img: "../../assets/sabina.png",
      nome: "Sabina",
      studi: "Sono al primo anno di master in psicologia all'università di Ginevra",
      durata: "Da circa un anno insegno con 2+2=0 mate e statistica",
      materia: "Insegno mate e statistica",
      city: "Essendo a Ginevra, do lezione principalmente online",
      hobby: "Nel tempo libero faccio sport, leggo o suono la chitarra",
      cosa: "2+2=0 per me è una bellissima iniziativa dove gli studenti possono aiutarsi a vicenda :)",
      motivazione: "Mi motiva nell'insegnamento vedere il miglioramento e la soddisfazione degli allievi",
      cit: "",
      orient: 0
    },
    {
      img: "../../assets/joel.png",
      nome: "Joel",
      studi: "Studio matematica e finanza all’università di Zurigo",
      durata: "Insegno dal liceo",
      materia: "Insegno prevalentemente matematica, ma anche fisica, italiano, tedesco e inglese! ",
      city: "Insegno principalmente nel Locarnese e online",
      hobby: "Mi piace giocare a calcio, arbitrare, andare in moto…. Insegnare!",
      cosa: "2+2=0 è un bellissimo metodo per crescere insieme, anche grazie all’aiuto di punti di vista più o meno diversi tra loro, imparando a capire che, metaforicamente parlando, 2+2 può far 4, ma anche 0!",
      motivazione: "Mi piace raggiungere miglioramenti miei e di chi aiuto e la soddisfazione di vedere la felicità quando chi aiuto riesce a migliorare",
      cit: "'There are two ways to do great mathematics. The first is to be smarter than everybody else. The second way is to be stupider than everybody else -- but persistent.' (Raoul Bott)",
      orient: 1
    },
    {
      img: "../../assets/greta.jpg",
      nome: "Greta",
      studi: "Studio Biologia all'università di Losanna, mi piacerebbe specializzarmi in biomedicina",
      durata: "A settembre sarà un anno che faccio parte di questo team di insegnanti!",
      materia: "Insegno varie materie, soprattutto Biologia (scienze) e lingue (francese, inglese e italiano)",
      city: "Insegno principalmente nel Mendrisiotto, ma vivendo a Losanna faccio tante lezioni online",
      hobby: "Adoro cucinare e soprattutto viaggiare e scoprire il mondo",
      cosa: "2+2=0 per me è l'occasione di poter aiutare e sostenere chi ne ha bisogno attraverso l'insegnamento; è un team di persone gentili e disponibili spinte da un obiettivo comune!",
      motivazione: "Mi piace molto aiutare ed essere un sostegno per i miei allievi, poter dare loro consigli e sostenerli",
      cit: "'Talk about a dream, try to make it real' Bruce Springsteen",
      orient: 0
    },
    {
      img: "../../assets/daela.jpg",
      nome: "Daela",
      studi: "Studio chimica all'università di Friborgo ",
      durata: "È più di un anno che faccio parte di questo team di insegnanti!",
      materia: "Insegno chimica",
      city: "Insegno nel Luganese e online",
      hobby: "Nel tempo libero mi piace cucinare e leggere",
      cosa: "2+2=0 é per me un'occasione per mostrare che la chimica può essere semplice e interessante",
      motivazione: "",
      cit: "",
      orient: 1
    },
    {
      img: "../../assets/user2.png",
      nome: "Elia",
      studi: "Studio ingegneria informatica alla SUPSI",
      durata: "Insegno da dicembre 2020 con 2+2=0",
      materia: "Insegno matematica e fisica",
      city: "Insegno principalmente nel Bellinzonese",
      hobby: "I miei hobby sono film, videogiochi e content creator su YouTube",
      cosa: "2+2=0 per me è una possibilità di insegnare ciò che ho appreso nel corso degli anni e aiutare i ragazzi in difficoltà",
      motivazione: "Il fatto di aiutare i ragazzi a fortificare le fondamenta del loro apprendimento mi motiva all’insegnamento e so quanto sia importate l’insegnamento individuale",
      cit: "",
      orient: 0
    },
    {
      img: "../../assets/alice.png",
      nome: "Alice",
      studi: "Studio lingua e letteratura inglese e francese presso l’Università di Neuchâtel. Mi piacerebbe diventare un’insegnante",
      durata: "Da marzo 2020 insegno con 2+2=0",
      materia: "Insegno inglese e francese",
      city: "Insegno nel Mendrisiotto ed eventualmente nel Luganese e online",
      hobby: "Durante il tempo libero mi piace molto cucinare, fare passeggiate in montagna e seguire lezioni di fitness. Amo viaggiare e passare del tempo con la mia famiglia",
      cosa: "Per me 2+2=0 è un’opportunità per cominciare a entrare nel mondo dell’insegnamento conoscendo nuovi allievi e condividendo con loro le mie conoscenze",
      motivazione: "Il fatto di aiutare i ragazzi a fortificare le fondamenta del loro apprendimento mi motiva all’insegnamento e so quanto sia importate l’insegnamento individuale",
      cit: "Difficult roads often lead to beautiful destinations – Zig Ziglar",
      orient: 1
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}
