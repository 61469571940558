<div id="corpo">
<div class="container">
  <label id="example-radio-group-label">Seleziona la scuola</label>
  <mat-radio-group
    aria-labelledby="example-radio-group-label"
    class="example-radio-group"
    [(ngModel)]="selectedSchool">
    <mat-radio-button class="example-radio-button" *ngFor="let school of schools" [value]="school" color="primary">
      {{school}}
    </mat-radio-button>
  </mat-radio-group>
</div>

<div [ngSwitch]="selectedSchool">
<form [formGroup]="medie" *ngSwitchCase="'Scuole Medie'">
  <mat-form-field>
    <mat-label>Cerca la materia che ti interessa</mat-label>
    <input type="text"
           matInput
           formControlName="medieGroup"
           required
           [matAutocomplete]="autoGroup">
      <mat-autocomplete #autoGroup="matAutocomplete">
        <mat-option *ngFor="let name of stateGroupOptions1 | async" [value]="name">
          {{name}}
        </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>

<form [formGroup]="licei" *ngSwitchCase="'Scuole superiori (liceo/commercio)'">
  <mat-form-field>
    <mat-label>Cerca la materia che ti interessa</mat-label>
    <input type="text"
           matInput
           formControlName="liceiGroup"
           required
           [matAutocomplete]="autoGroup">
      <mat-autocomplete #autoGroup="matAutocomplete">
        <mat-option *ngFor="let name of stateGroupOptions2 | async" [value]="name">
          {{name}}
        </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>

<form [formGroup]="professionali" *ngSwitchCase="'Scuole professionali/apprendistati'">
  <mat-form-field>
    <mat-label>Cerca la materia che ti interessa</mat-label>
    <input type="text"
           matInput
           formControlName="profGroup"
           required
           [matAutocomplete]="autoGroup">
      <mat-autocomplete #autoGroup="matAutocomplete">
        <mat-option *ngFor="let name of stateGroupOptions3 | async" [value]="name">
          {{name}}
        </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
</div>

<div class="container" [ngSwitch]="selectedSchool">
  <div *ngSwitchCase="'Scuole Medie'">
    <div *ngIf="medie.get('medieGroup').touched && medie.get('medieGroup').valid">
      <table class="table">
        <thead>
          <tr>
            <!--<th scope="col">#</th>-->
            <!--<th scope="col">Materia</th>-->
            <th scope="col"></th>
            <th scope="col">Lezioni a domicilio</th>
            <th scope="col"  id="connpc">Lezioni online <a routerLink="/Skype"><button type="button" class="btn btn-link">(cosa sono?)</button></a></th>
            <th scope="col"  id="conntel">Lezioni online <a routerLink="/Skype"><i class="fas fa-question-circle"></i></a></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!--<th scope="row">1</th>-->
            <td>{{ medie.get('medieGroup').value }}</td>
            <td><i class="fas fa-check"></i></td>
            <td><i class="fas fa-check"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngSwitchCase="'Scuole professionali/apprendistati'">
    <div *ngIf="professionali.get('profGroup').touched && professionali.get('profGroup').valid">
      <table class="table">
        <thead>
          <tr>
            <!--<th scope="col">#</th>-->
            <!--<th scope="col">Materia</th>-->
            <th scope="col"></th>
            <th scope="col">Lezioni a domicilio</th>
            <th scope="col"  id="connpc">Lezioni online <a routerLink="/Skype"><button type="button" class="btn btn-link">(cosa sono?)</button></a></th>
            <th scope="col"  id="conntel">Lezioni online <a routerLink="/Skype"><i class="fas fa-question-circle"></i></a></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!--<th scope="row">1</th>-->
            <td>{{ professionali.get('profGroup').value }}</td>
            <td><i class="fas fa-check"></i></td>
            <td><i class="fas fa-check"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngSwitchCase="'Scuole superiori (liceo/commercio)'">
    <div *ngIf="licei.get('liceiGroup').touched && licei.get('liceiGroup').valid">
      <table class="table">
        <thead>
          <tr>
            <!--<th scope="col">#</th>-->
            <!--<th scope="col">Materia</th>-->
            <th scope="col"></th>
            <th scope="col">Lezioni a domicilio</th>
            <th scope="col"  id="connpc">Lezioni online <a routerLink="/Skype"><button type="button" class="btn btn-link">(cosa sono?)</button></a></th>
            <th scope="col"  id="conntel">Lezioni online <a routerLink="/Skype"><i class="fas fa-question-circle"></i></a></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <!--<th scope="row">1</th>-->
            <td>{{ licei.get('liceiGroup').value }}</td>
            <td><i class="fas fa-check"></i></td>
            <td><i class="fas fa-check"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</div>
