<div class="card mb-3" *ngFor="let soc of soci" id="insegnanti">
  <h3>{{soc.responsabile}}</h3>
  <div [ngSwitch]="soc.orient">
    <div *ngSwitchCase="0">
      <div class="row no-gutters" id="riga">
        <div class="col-md-6">
          <img src="{{ soc.img }}" class="card-img" alt="...">
        </div>
        <div class="col-md-6">
          <div class="card-body">
            <h1 class="card-title">{{ soc.nome }}</h1>
            <p class="card-text">{{ soc.city }}</p>
            <p class="card-text">{{ soc.studi }}</p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <p class="card-text-footer">{{ soc.telefono }}</p>
        <!--<a href="mailto:soc.email"><p class="card-text-footer" id="email1">{{ soc.email }}</p></a>-->
      </div>
    </div>

    <div *ngSwitchCase="1">
      <div class="row no-gutters" id="riga">
        <div class="col-md-6">
          <div class="card-body">
            <h1 class="card-title">{{ soc.nome }}</h1>
            <p class="card-text">{{ soc.city }}</p>
            <p class="card-text">{{ soc.studi }}</p>
          </div>
        </div>
        <div class="col-md-6">
        <img src="{{ soc.img }}" class="card-img" alt="...">
        </div>
      </div>
      <div class="card-footer">
        <p class="card-text-footer">{{ soc.telefono }}</p>
        <!--<a href="mailto:soc.email"><p class="card-text-footer" id="email2">{{ soc.email }}</p></a>-->
      </div>
    </div>
  </div>
</div>

<!--<h3>Responsabile ripetizioni</h3>
<div class="card mb-3" *ngFor="let soc of stagista" id="insegnanti">
  <div [ngSwitch]="soc.orient">
    <div *ngSwitchCase="0">
      <div class="row no-gutters" id="riga">
        <div class="col-md-6">
          <img src="{{ soc.img }}" class="card-img" alt="...">
        </div>
        <div class="col-md-6">
          <div class="card-body">
            <h1 class="card-title">{{ soc.nome }}</h1>
            <p class="card-text">{{ soc.city }}</p>
            <p class="card-text">{{ soc.studi }}</p>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <p class="card-text-footer">{{ soc.telefono }}</p>
      </div>
    </div>

    <div *ngSwitchCase="1">
      <div class="row no-gutters" id="riga">
        <div class="col-md-6">
          <div class="card-body">
            <h1 class="card-title">{{ soc.nome }}</h1>
            <p class="card-text">{{ soc.city }}</p>
            <p class="card-text">{{ soc.studi }}</p>
          </div>
        </div>
        <div class="col-md-6">
        <img src="{{ soc.img }}" class="card-img" alt="...">
        </div>
      </div>
      <div class="card-footer">
        <p class="card-text-footer">{{ soc.telefono }}</p>
      </div>
    </div>
  </div>
</div>-->
