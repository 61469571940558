import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Club } from './club.model';

@Injectable()
export class ClubService {
  members: Club[];
    constructor(private http: HttpClient) {}
    clubChanged = new Subject<Club[]>();


    storeMembers(membri: Club[]) {
      return this.http.put('https://dante-ng-2piu2.firebaseio.com/membri.json', membri);
  }

  setMembers(members: Club[]) {
    this.members = members;
    //this.clubChanged.next(this.members.slice());
    this.clubChanged.next(this.members);
  }

  getMembers() {
    //return this.members.slice();
    return this.members;
  }
}
