import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-box-right',
  templateUrl: './box-right.component.html',
  styleUrls: ['./box-right.component.min.css']
})
export class BoxRightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
