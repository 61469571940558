import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {AskStorageService} from './ask-storage.service';
import { MailService } from '../shared/mail.service';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.min.scss']
})
export class FAQComponent implements OnInit {
  @ViewChild('f', {static : true}) signupForm: NgForm;
  BACKEND_URL = environment.apiUrl + 'contact/1/send_mail_ask/';
  ask2 = {
    nome: '',
    email: '',
    text: '',
    consenso: true
  };

  ask = [
    {
      nome: 'Pippo',
      email: 'pluto@pippo.com',
      text: 'ciao sono una mamma pancina',
      consenso: true

    }];
    submitted = false;
    selezione = false;
    isCollapsed1 = true;
    isCollapsed2 = true;
    isCollapsed3 = true;
    isCollapsed4 = true;
    isCollapsed5 = true;
    isCollapsed6 = true;



  constructor(private askStorageService: AskStorageService, private mailService: MailService) { }

  ngOnInit() {
  }


  onSelect() {
    this.selezione=!this.selezione;

  }

  onAsked() {
    this.submitted = true;


    this.ask.push({
      nome: this.signupForm.value.askData.nome,
      email: this.signupForm.value.askData.email,
      text: this.signupForm.value.askData.text,
      consenso: this.selezione
    });
    this.ask2.nome = this.signupForm.value.askData.nome,
    this.ask2.email = this.signupForm.value.askData.email,
    this.ask2.text = this.signupForm.value.askData.text,
    this.ask2.consenso = this.selezione;


    this.mailService.sendMail(this.BACKEND_URL, this.ask2);

    this.askStorageService.storeAsk(this.ask)
      .subscribe(
        (response) => console.log(response),
        (error) => console.log(error)
      );
    /*
    console.log(this.signupForm.value.askData.nome);
    console.log(this.signupForm.value.askData.email);
    console.log(this.signupForm.value.askData.text);
    console.log(this.selezione);
    */

    this.signupForm.reset();



  }



}


