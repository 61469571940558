<p>
    
    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
            Struttura dei corsi <i class="fas fa-chevron-down" [hidden]="!isCollapsed"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed"></i>
    </button>
  </p>
  <div id="collapseExample" [ngbCollapse]="isCollapsed">
    <div class="card">
      <div class="card-body">
        5 lezioni dal lunedì al venerdì dalle 8:00 alle 12:00. 
        <br>
        <button type="button" class="btn btn-link btn-xs" (click)="onMostra()" [hidden]="!Nascosto" id="link">Mostra altro...</button>
        <br>
         <!-- 2+2=0 organizza corsi per studenti di tutte le classi di scuole Medie e Superiori. Ogni corso dura 5 giorni, dal lunedì al venerdì dalle 8:00 alle 12:00. Durante le lezioni verranno approfonditi gli aspetti più importanti del programma scolastico. Le classi non saranno composte da più di 6 studenti alla volta per poter garantire ad ognuno la maggior attenzione possibile -->
          <p id="info" [hidden]="Nascosto">- Garantiamo il ripasso dell'intero programma scolastico nelle 20ore a disposizione.  </p>
          <p id="info" [hidden]="Nascosto">- Adattiamo le lezioni alle esigenze personali dei singoli. </p>
          <p id="info" [hidden]="Nascosto">- Assegnamo e correggiamo esercizi quotidianamente al fine di verificare l'apprendimento.</p>
          <button type="button" class="btn btn-link btn-xs" (click)="onMostra()" [hidden]="Nascosto" id="link">Mostra meno</button>
      </div>
    </div>
  </div>