import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import {FormsModule} from '@angular/forms';


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.toLowerCase().indexOf(filterValue) === 0);
};

@Component({
  selector: 'app-materie',
  templateUrl: './materie.component.html',
  styleUrls: ['./materie.component.scss']
})
export class MaterieComponent implements OnInit {
  selectedSchool: string;
  schools: string[] = ['Scuole Medie', 'Scuole superiori (liceo/commercio)', 'Scuole professionali/apprendistati'];
  medie: FormGroup = this._formBuilder.group({
    medieGroup: '',
  });
  licei: FormGroup = this._formBuilder.group({
    liceiGroup: '',
  });
  professionali: FormGroup = this._formBuilder.group({
    profGroup: '',
  });


  materieMedieGroups: string[] = [
    'Francese', 'Geografia', 'Italiano', 'Inglese', 'Latino', 'Matematica', 'Scienze', 'Storia', 'Tedesco'
];

  materieLiceoGroups: string[] = [
    'Biologia', 'Chimica', 'Economia', 'Francese', 'Fisica', 'Geografia', 'Italiano', 'Inglese',
    'Latino', 'Matematica', 'Storia', 'Tedesco'
  ];

  materieProfGroups: string[] = [
    'Biologia', 'Chimica', 'Economia', 'Francese', 'Fisica', 'Geografia',
    'Italiano', 'Inglese', 'Matematica', 'Storia', 'Tedesco'
  ];

  stateGroupOptions1: Observable<string[]>;
  stateGroupOptions2: Observable<string[]>;
  stateGroupOptions3: Observable<string[]>;

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit() {
    this.stateGroupOptions1 = this.medie.get('medieGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterMedieGroup(value))
      );
    this.stateGroupOptions2 = this.licei.get('liceiGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterLiceoGroup(value))
      );
    this.stateGroupOptions3 = this.professionali.get('profGroup')!.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterProfGroup(value))
      );
  }


  private _filterMedieGroup(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.materieMedieGroups.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterLiceoGroup(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.materieLiceoGroups.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterProfGroup(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.materieProfGroups.filter(option => option.toLowerCase().includes(filterValue));
  }

}
