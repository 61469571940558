import { Component, OnInit } from '@angular/core';
import { DataStorageService } from '../shared/data-storage.service';
import { faChalkboardUser, faUsers } from '@fortawesome/free-solid-svg-icons';

/*@Component({
  selector: 'ngdb-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Join us!</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [hidden]="submitted" (ngSubmit)="onJoin()" #f="ngForm">
        <div class="form" id="join-data" ngModelGroup="joinData" #userData="ngModelGroup">
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" ngModel name="email" required email #email="ngModel">
          </div>
          <div class="form-group">
            <label for="nome">Nome</label>
            <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Nome" ngModel name="nome" required>
          </div>
          <div class="form-group">
            <label for="cognome">Cognome</label>
            <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Cognome" ngModel name="cognome" required>
          </div>
          <div class="form-group">
            <label for="cognome">Che cosa studi?</label>
            <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Che cosa studi" ngModel name="corsouni" required>
          </div>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Cosa saresti disposto a insegnare?</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Fai una lista delle materie" rows="8" ngModel name="text"></textarea>
          </div>
          <button type="submit" class="btn btn-primary" [disabled]="!f.valid">Invia</button>
        </div>
      </form>
      <div [hidden]="!submitted" class="contact-message">
        <div class="contact-success">
          <div class="success">
            <h2>Congratulazioni!</h2>
            <h4>Grazie per averci contattati, ti risponderemo al più presto.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `

})
export class NgbdModalContent {
  @ViewChild('f') signupForm: NgForm;
  joinform = [
    {
      nome: 'prova',
      cognome: 'prova',
      email: 'prova',
      corsouni: 'prova',
      materie: 'prova'
    }
  ];
  nome = '';
  cognome = '';
  email = '';
  corsouni = '';
  materie = '';
  constructor(public activeModal: NgbActiveModal, private joinStorageService: JoinStorageService) {}
  submitted = false;

  onJoin() {
    this.submitted = true;
    this.joinform.push({
    email: this.signupForm.value.joinData.email,
    nome: this.signupForm.value.joinData.nome,
    cognome: this.signupForm.value.joinData.cognome,
    corsouni: this.signupForm.value.joinData.corsouni,
    materie: this.signupForm.value.joinData.text
  });

  this.joinStorageService.storeJoin(this.joinform)
    .subscribe(
      (response) => console.log(response),
      (error) => console.log(error)
    );
  this.signupForm.reset();
  }
}*/

@Component({
  selector: 'app-chi-siamo',
  templateUrl: './chi-siamo.component.html',
  styleUrls: ['./chi-siamo.component.scss']
})
export class ChiSiamoComponent implements OnInit {
  ore: any;
  allievi: number;
  faUsers = faUsers;
  faChalkboardUser = faChalkboardUser;

  constructor(private dataStorageService: DataStorageService) {}

  ngOnInit() {
    this.dataStorageService.getHoursBE()
      .subscribe(response => {
        this.ore = Math.round(response);
        console.log(response)
      });

    this.dataStorageService.getNRAllievi()
      .subscribe(response => {
        this.allievi = 282 + response;
      });
  }

  /*open() {
    const modalRef = this.modalService.open(NgbdModalContent);
  }*/

}


