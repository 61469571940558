import { Component, OnInit } from '@angular/core';
import { DataStorageService } from '../shared/data-storage.service';

@Component({
  selector: 'app-treedom',
  templateUrl: './treedom.component.html',
  styleUrls: ['./treedom.component.scss']
})
export class TreedomComponent implements OnInit {
  ore: any;

  constructor(private dataStorageService: DataStorageService) { }

  ngOnInit() {
    /*this.dataStorageService.getHours()
    .subscribe(response => {
      this.ore = response.ore % 50;
      console.log(this.ore)
    });*/
    this.dataStorageService.getHoursBE()
      .subscribe(response => {
        this.ore = response % 50;
        this.ore = Math.round(this.ore);
      });
  }

}
