import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { Http } from '@angular/http';

@Injectable()
export class ValutazioneStorageService {
    constructor(private http: HttpClient) {}
    storeUsers(val: any[]) {
        return this.http.post('https://dante-ng-2piu2.firebaseio.com/valutazione.json', val);
    }
}
