import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-recensioni',
  templateUrl: './recensioni.component.html',
  styleUrls: ['./recensioni.component.scss']
})
export class RecensioniComponent implements OnInit {


  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;

  recensione = 1;
  i = 1;
  j = 2;
  k = 3;

  uno = true;
  due = false;
  tre = false;

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;
  //@ViewChild('carousel') carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
  constructor() { }




  ngOnInit() {
      /*setTimeout(() => {
        for (this.i = 1; this.i <= 3; ++this.i) {
          this.recensione = this.i;
          console.log('i=', this.i);
        }
      }, 4000);*/

    let timerId = setInterval(() => {this.recensione = 1; }, 4000);
    let timerId2 = setInterval(() => {this.recensione = 2; }, 9000);
    let timerId3 = setInterval(() => {this.recensione = 3; }, 13000);
    let timerId4 = setInterval(() => {this.recensione = 4; }, 16000);

  }


}
