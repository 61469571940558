<ngb-carousel [interval]="5000" [pauseOnHover]="pauseOnHover" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators" (slide)="onSlide($event)">
  <ng-template ngbSlide *ngFor="let feedback of feedbacks">
    <div class="picsum-img-wrapper">
      <div class="row">
        <!--<div class="col-1"><i class="fas fa-user-circle fa-5x"></i></div>-->
        <div class="col-1 col-xs-8"></div>
        <div class="col-9 col-xs-8">
          <p>{{feedback}}
          </p>
        </div>
        <div class="col-1 col-xs-8"></div>
      </div>
    </div>
    <!--<div class="carousel-caption">
      <h3>No mouse navigation</h3>
      <p>This carousel hides navigation arrows and indicators.</p>
    </div>-->
  </ng-template>
</ngb-carousel>
