import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faCoffee, faGift } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ngdb-modal-pubb',
  template: `
  <div class="modal-header" id="login-modal">
    <!--<h4 class="modal-title">Novità</h4>-->
    <h4 class="modal-title">Da non perdere!</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
  <!--<h4 style="color: red; font-weight: bold; text-align:center">Buona Pasqua</h4>
  <p><i style="padding-left:40%;margin-top:5%;margin-bottom:5%" class="fas fa-carrot fa-5x"></i></p>
  <p>Il team di 2+2=0 vi augura una buona Pasqua</p>-->
  <!--<h4 style="color: red; font-weight: bold; text-align:center">Corso di Carnevale 2022</h4>-->
  <!--<h4 style="color: red; font-weight: bold; text-align:center">Python in 6h!</h4>
  <p><i style="padding-left: 40%" class="fab fa-earlybirds fa-5x"></i></p>-->
  <!--<p style="padding-top:5%;padding-bottom:5%"><fa-icon style="padding-left: 42.5%;" [icon]="faCoffee" size="4x"></fa-icon></p>-->
  <!--<p>10% di sconto sull'intero corso</p>
  <p>Iscriviti e paga entro il 1. aprile per approfittarne</p>-->
  <!--<p>Sconto del 10% su uno dei nostri corsi estivi: <a routerLink="/CorsiRagazzi/CorsiInverno" style="color:blue;text-decoration:underline">Corsi estivi</a>, disponibili la prossima estate</p>
  <p>Scopri domenica sui nostri social se hai vinto</p>-->

  <h4 style="color: red; font-weight: bold; text-align:center">Tornano i nostri corsi estivi!!!</h4>
  <p><i style="padding-left: 40%" class="fas fa-umbrella-beach fa-5x"></i></p>
  <a class="btn btn-light btn-lg" routerLink="/CorsiRagazzi/CorsiEstivi" style="width: 100%" (click)="activeModal.close('Close click')">Ai corsi estivi!</a>

  <h4 style="color: red; font-weight: bold; text-align: center; margin-top: 20px">Camp di tennis e studio!!!</h4>
  <p style="padding-left: 40%;"><mat-icon style="font-size: 100px">sports_tennis</mat-icon></p>
  <p>A giugno, luglio e agosto. Trova tutte le info qui:</p>
  <a class="btn btn-light btn-lg" href="https://www.scuolatennis-bellinzona.ch/campus" target="_blank" style="width: 100%" (click)="activeModal.close('Close click')">Ai camp!</a>

  <!--<h4 style="color: red; font-weight: bold; text-align:center">Novità!!!</h4>
  <h6>Corso di robotica con Arduino</h6>
  <img src="../../assets/arduino-2168193_1920-min.png" alt="" style="width:100%; margin-top:2%; margin-bottom:2%"-->
  <!--<p><i style="padding-left: 40%" class="fas fa-gift fa-5x"></i></p>-->
  <!--<p><i style="padding-left: 40%" class="fas fa-leaf fa-5x"></i></p>
  <p style="text-align:center">Non perderti le promozioni di aprile 2021: il Mese Verde di 2+2=0</p>-->
  <!--<p style="color: red; font-weight: bold; text-align:center">Solo 12.25.-/h!!!</p>-->
  <!--<p>Trova tutte le info qui:</p>
  <a class="btn btn-light btn-lg" routerLink="/CorsiAdulti" style="width: 100%" (click)="activeModal.close('Close click')">Info!</a>
  <a class="btn btn-light btn-lg" routerLink="/Treedom" style="width: 100%" (click)="activeModal.close('Close click')">Approfittane!</a>-->





  <!--<p><i style="padding-left: 40%" class="fas fa-graduation-cap fa-5x"></i></p>
  <p style="text-align:center">Non perderti la novità di quest anno di 2+2=0: i corsi di preparazione alla Maturità 2021</p>-->
  <!--<p>Trova tutte le info qui:</p>
  <a class="btn btn-light btn-lg" routerLink="/CorsiAdulti" style="width: 100%" (click)="activeModal.close('Close click')">Approfittane!</a>-->
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
  `
})

export class NgbdModalPubb {
  constructor(public activeModal: NgbActiveModal) {}
  faCoffee = faGift

}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.min.scss']
})
export class HomeComponent implements OnInit {
  netImage: any = "../immagini/logo.png";
  modal = true;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    /*if (this.modal) {
      const modalRef = this.modalService.open(NgbdModalPubb);
      this.modal = false;
    }*/
  }

  /*onOpenPub() {
    if (this.modal) {
      const modalRef = this.modalService.open(NgbdModalPubb);
      this.modal = false;
    }
  }*/

}
