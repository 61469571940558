<!--<div class="container-fluid" [ngSwitch]="orientamento">-->
<body>
  <!--<h2>Insegnanti</h2>-->
  <div class="card mb-3" *ngFor="let insegnant of insegnanti" id="insegnanti">
    <div [ngSwitch]="insegnant.orient">
      <div *ngSwitchCase="0">
        <div class="row no-gutters" id="riga">
          <div class="col-md-6">
            <img src="{{ insegnant.img }}" class="card-img" alt="{{ insegnant.nome }}" loading="lazy">
          </div>
          <div class="col-md-6">
            <div class="card-body">
              <h2 class="card-title">{{ insegnant.nome }}</h2>
              <p class="card-text">{{ insegnant.studi }}</p>
              <!--<p class="card-text">{{ insegnant.durata }}</p>-->
              <p class="card-text">{{ insegnant.materia }}</p>
              <p class="card-text">{{ insegnant.city }}</p>
              <p class="card-text">{{ insegnant.hobby }}</p>
            </div>
          </div>
          <p class="card-cosa">{{ insegnant.cosa }}</p>
        </div>
        <div class="card-footer">
          <p class="card-testo">{{ insegnant.motivazione }}</p>
          <small class="text-muted">{{ insegnant.cit }}</small>
        </div>
      </div>

      <div *ngSwitchCase="1">
        <div class="row no-gutters" id="riga">
          <div class="col-md-6">
            <div class="card-body">
              <h2 class="card-title">{{ insegnant.nome }}</h2>
              <p class="card-text">{{ insegnant.studi }}</p>
              <!--<p class="card-text">{{ insegnant.durata }}</p>-->
              <p class="card-text">{{ insegnant.materia }}</p>
              <p class="card-text">{{ insegnant.city }}</p>
              <p class="card-text">{{ insegnant.hobby }}</p>
            </div>
          </div>
          <div class="col-md-6">
          <img src="{{ insegnant.img }}" class="card-img" alt="{{ insegnant.nome }}" loading="lazy">
          </div>
          <p class="card-cosa">{{ insegnant.cosa }}</p>
        </div>
        <div class="card-footer">
          <p class="card-testo">{{ insegnant.motivazione }}</p>
          <small class="text-muted">{{ insegnant.cit }}</small>
        </div>
      </div>
    </div>

  </div>

<!--<div class="card mb-3" *ngFor="let insegnant of insegnantisx" id="insegnanti">
  <div class="row no-gutters" id="riga">
    <div class="col-md-6">
      <img src="{{ insegnant.img }}" class="card-img" alt="...">
    </div>
    <div class="col-md-6">
      <div class="card-body">
        <h2 class="card-title">{{ insegnant.nome }}</h2>
          <p class="card-text">{{ insegnant.studi }}</p>
          <p class="card-text">{{ insegnant.durata }}</p>
          <p class="card-text">{{ insegnant.city }}</p>
          <p class="card-text">{{ insegnant.hobby }}</p>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <p class="card-text">{{ insegnant.motivazione }}</p>
    <small class="text-muted">{{ insegnant.cit }}</small>
  </div>
</div>

<div class="card mb-3" *ngFor="let insegnant of insegnantidx" id="insegnanti">
  <div class="row no-gutters" id="riga">
    <div class="col-md-6">
      <div class="card-body">
        <h2 class="card-title">{{ insegnant.nome }}</h2>
          <p class="card-text">{{ insegnant.studi }}</p>
          <p class="card-text">{{ insegnant.durata }}</p>
          <p class="card-text">{{ insegnant.city }}</p>
          <p class="card-text">{{ insegnant.hobby }}</p>
      </div>
    </div>
    <div class="col-md-6">
      <img src="{{ insegnant.img }}" class="card-img" alt="...">
    </div>
  </div>
  <div class="card-footer">
    <p class="card-text">{{ insegnant.motivazione }}</p>
    <small class="text-muted">{{ insegnant.cit }}</small>
  </div>
</div>-->

<!--<div class="card mb-3" *ngFor="let insegnant of insegnanti" id="insegnanti">
  <div class="row no-gutters">
    <div class="col-md-6">
      <img src="{{ insegnant.img }}" class="card-img" alt="...">
    </div>
    <div class="col-md-6">
      <div class="card-body">
        <h2 class="card-title">{{ insegnant.nome }}</h2>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">{{ insegnant.studi }}</li>
          <li class="list-group-item">{{ insegnant.durata }}</li>
          <li class="list-group-item">{{ insegnant.city }}</li>
          <li class="list-group-item">{{ insegnant.hobby }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <p class="card-text">{{ insegnant.motivazione }}</p>
    <small class="text-muted">{{ insegnant.cit }}</small>
  </div>
</div>-->
<!--</div>-->
</body>
