<header id="TreedomHeader">
  <div class="container-fluid">

  <h1>
    Diventa socio di 2+2=0
  </h1>

  </div>
</header>

<body>
  <header id="pc">
  <h1>Benvenuto al 3-Club</h1>
  </header>
  <header id="cell">
    <h1>Benvenuto</h1>
    <h1>al 3-Club</h1>
  </header>
  <br>
  <h3>Il cuore pulsante di 2+2=0</h3>

  <main>
    <p>Il 3-Club è un club esclusivo che racchiude in sé la triade di valori su cui 2+2=0 si fonda:</p>
    <p>non solo contribuisce a supportare i ragazzi che si trovano in difficoltà a scuola,</p>
    <p>non solo sostiene studenti universitari in gamba,</p>
    <p>ma partecipa attivamente anche alla lotta contro la deforestazione e il cambiamento climatico.</p>
    <br>
    <p>Oltre a promuovere i nostri e vostri valori comuni, il 3-Club ha anche lo scopo di ricompensare la generosità dei suoi membri. Essere membri del 3-Club significa infatti ottenere lezioni gratuite e sconti. Inoltre, solo chi fa parte del 3-Club può essere socio di 2+2=0!</p>
    <!--<p>Essere membro del 3-Club significa infatti ottenere lezioni gratuite e sconti. Inoltre, solo chi fa parte del 3-Club può essere socio di 2+2=0!</p>-->

  </main>

  <!--<h5>Lista dei membri del 3-Club:</h5>
  <section>
  <table class="table table-borderless table-hover table-success table-striped">
    <thead>
      <tr>
        <th scope="col">Rango</th>
        <th scope="col">Nome</th>
        <th scope="col">#Alberi</th>
        <th scope="col">Membro da</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let member of members">
        <td>{{ member.rango }}</td>
        <td>{{ member.nome }}</td>
        <td>{{ member.alberi }}</td>
        <td>{{ member.da }}</td>
      </tr>
    </tbody>
  </table>
  </section>-->

  <section>
    <p>Anche tu vuoi diventare socio e far parte del 3-Club?</p>
    <!--<p>Grazie al 3-Club avrai accesso a lezioni gratuite e sconti sui nostri corsi.</p>-->
    <p>Contattaci qui sotto</p>
  </section>
</body>
