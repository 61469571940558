import { Component, OnInit } from '@angular/core';
import { faCoffee, faEnvelope, faGift, faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-z-footer',
  templateUrl: './z-footer.component.html',
  styleUrls: ['./z-footer.component.scss']
})
export class ZFooterComponent implements OnInit {

  constructor() { }


  faPhone = faPhone
  faEnvelope = faEnvelope

  ngOnInit() {
  }

}
