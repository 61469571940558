import { Component, OnInit, ViewChild } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { DataStorageService } from '../shared/data-storage.service';
import { MailService } from '../shared/mail.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'ngdb-modal-privato',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Iscriviti!</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [hidden]="submitted" (ngSubmit)="onIscriviti()" #f="ngForm">
        <div class="form" id="iscr-data" ngModelGroup="iscrData" #iscrData="ngModelGroup">
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" ngModel name="email" required email #email="ngModel">
          </div>
          <div class="form-group">
            <label for="nome">Nome</label>
            <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Nome" ngModel name="nome" required>
          </div>
          <div class="form-group">
            <label for="cognome">Cognome</label>
            <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Cognome" ngModel name="cognome" required>
          </div>
          <div class="form-group">
            <label for="cognome">Nr. telefono</label>
            <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Nr. telefono" ngModel name="telefono" required>
          </div>
          <h5>Che tipo di lezioni ti interessano?</h5>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" (click)="onLez('Presenza')">
            <label class="form-check-label" for="exampleRadios1">
              Classiche frontali
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" (click)="onLez('Online')">
            <label class="form-check-label" for="exampleRadios2">
              Online
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3" (click)="onLez('Online o presenza')">
            <label class="form-check-label" for="exampleRadios3">
              Vanno bene entrambi
            </label>
          </div>
          <hr>
          <h5>Seleziona una classe</h5>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="terzo" id="inlineRadio1" value="'Medie'" ngModel (click)="onSel1()">
            <label class="form-check-label" for="inlineRadio1">Medie</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="terzo" id="inlineRadio2" value="'Liceo'" ngModel (click)="onSel2()">
            <label class="form-check-label" for="inlineRadio2">Liceo</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="terzo" id="inlineRadio3" value="'Commercio'" ngModel (click)="onSel3()">
            <label class="form-check-label" for="inlineRadio3">Commercio</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="terzo" id="inlineRadio3" value="'Altro'" ngModel (click)="onSel4()">
            <label class="form-check-label" for="inlineRadio3">Altro</label>
          </div>
          <div [ngSwitch]="selezione" [hidden]="hidden">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" (click)="onMateria('Mate')">
                <label class="form-check-label" for="defaultCheck1">
                  Matematica
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Tedesco')">
                <label class="form-check-label" for="defaultCheck2">
                  Tedesco
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Italiano')">
                <label class="form-check-label" for="defaultCheck2">
                  Italiano
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Inglese')">
                <label class="form-check-label" for="defaultCheck2">
                  Inglese
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Geografia')">
                <label class="form-check-label" for="defaultCheck2">
                  Geografia
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Storia')">
                <label class="form-check-label" for="defaultCheck2">
                  Storia
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Francese')">
                <label class="form-check-label" for="defaultCheck2">
                  Francese
                </label>
              </div>
            <div *ngSwitchCase="'Medie'">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Scienze')">
                <label class="form-check-label" for="defaultCheck2">
                  Scienze
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Latino')">
                <label class="form-check-label" for="defaultCheck2">
                  Latino
                </label>
              </div>
            </div>
            <div *ngSwitchCase="'Liceo'">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Fisica')">
                <label class="form-check-label" for="defaultCheck2">
                  Fisica
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Chimica')">
                <label class="form-check-label" for="defaultCheck2">
                  Chimica
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Latino')">
                <label class="form-check-label" for="defaultCheck2">
                  Latino
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Economia')">
                <label class="form-check-label" for="defaultCheck2">
                  Economia
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Biologia')">
                <label class="form-check-label" for="defaultCheck2">
                  Biologia
                </label>
              </div>
            </div>
            <div *ngSwitchCase="'Commercio'">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Economia')">
                <label class="form-check-label" for="defaultCheck2">
                  Economia
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Scienze')">
                <label class="form-check-label" for="defaultCheck2">
                  Scienze
                </label>
              </div>
            </div>
            <div *ngSwitchCase="'Altro'">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Economia')">
                <label class="form-check-label" for="defaultCheck2">
                  Economia
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Biologia')">
                <label class="form-check-label" for="defaultCheck2">
                  Biologia
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Fisica')">
                <label class="form-check-label" for="defaultCheck2">
                  Fisica
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Chimica')">
                <label class="form-check-label" for="defaultCheck2">
                  Chimica
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Scienze')">
                <label class="form-check-label" for="defaultCheck2">
                  Scienze
                </label>
              </div>
            </div>
          </div>
        <br>
        <hr>
        <div class="form-group">
          <label for="exampleFormControlTextarea1">Osservazioni</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Facoltativo" rows="8" ngModel name="text"></textarea>
        </div>
        <hr>
        <div class="form-group">
          <label for="nome">Sei in posssesso di buoni sconto?</label>
          <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Inserisci codice sconto" ngModel name="code">
        </div>
        <button type="submit" class="btn btn-primary rounded-pill" [disabled]="!f.valid">Invia</button>
        </div>
      </form>
      <div [hidden]="!submitted" class="contact-message">
        <div class="contact-success">
          <div class="success">
            <h2>Congratulazioni!</h2>
            <h4>Grazie per averci contattati, ti risponderemo al più presto.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `

})
export class NgbdModalPrivato {
  @ViewChild('f', {static : true}) signupForm: NgForm;

  BACKEND_URL = environment.apiUrl + 'contact/1/send_mail_enroll/';
  iscritti2 = {
    nome: '',
    cognome: '',
    email: '',
    telefono: '',
    corso: '',
    select: '',
    materia: [],
    oss: '',
    codice: '',
    lezione: ''
  };

  hidden = true;
  iscritti = [
    {
      nome: 'prova',
      cognome: 'prova',
      email: 'prova',
      corso: 'prova',
      select: 'prova',
      materia: [],
      oss: 'prova',
      codice: 'prova',
      lezione: '3'
    }
  ];
  constructor(public activeModal: NgbActiveModal, private dataStorageService: DataStorageService, private mailService: MailService) {}
  submitted = false;
  selezione = null;
  lezione = null;
  materia = [];
  oss = '';
  codice = '';

  onSel1() {
    this.hidden = false;
    this.selezione = 'Medie';//'3.1';
  }
  onSel2() {
    this.hidden = false;
    this.selezione = 'Liceo';//'3.2';
  }
  onSel3() {
    this.hidden = false;
    this.selezione = 'Commercio';//'3.3';
  }
  onSel4() {
    this.hidden = false;
    this.selezione = 'Altro';//'3.4';
  }
  onEx() {
    this.hidden = false;
  }

  onLez(i) {
    this.lezione = i;
  }

  onMateria(a) {
    this.materia.push(a);
  }

  onIscriviti() {
    this.submitted = true;
    this.iscritti.push({
      email: this.signupForm.value.iscrData.email,
      nome: this.signupForm.value.iscrData.nome,
      cognome: this.signupForm.value.iscrData.cognome,
      corso: this.signupForm.value.iscrData.corso,
      select: this.signupForm.value.iscrData.terzo,
      oss: this.signupForm.value.iscrData.text,
      codice: this.signupForm.value.iscrData.code,
      materia: this.materia,
      lezione: this.lezione
    });

    this.iscritti2.email = this.signupForm.value.iscrData.email,
    this.iscritti2.nome = this.signupForm.value.iscrData.nome,
    this.iscritti2.cognome = this.signupForm.value.iscrData.cognome,
    this.iscritti2.telefono = this.signupForm.value.iscrData.telefono,
    //this.iscritti2.corso = this.signupForm.value.iscrData.corso,
    this.iscritti2.select = this.signupForm.value.iscrData.terzo,
    this.iscritti2.lezione = this.lezione,
    this.iscritti2.materia = this.materia,
    this.iscritti2.codice = this.signupForm.value.iscrData.code,
    this.iscritti2.oss = this.signupForm.value.iscrData.text,

    this.mailService.sendMail(this.BACKEND_URL, this.iscritti2);

    this.dataStorageService.storeUsers(this.iscritti)
      .subscribe(
        (response) => console.log(response),
        (error) => console.log(error)
      );

    this.signupForm.reset();
  }
}

@Component({
  selector: 'ngdb-modal-recensione',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Recensione</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [hidden]="submitted" (ngSubmit)="onOp()" #f="ngForm">
      <div class="form" id="op-data" ngModelGroup="opData2" #userRec="ngModelGroup">
        <div class="form-row">
          <!--<div class="form-group col-md-4">
            <label for="inputEmail4">Nome</label>
            <input type="text" class="form-control" id="inputEmail4" placeholder="Nome" ngModel name="nome" required>
          </div>
          <div class="form-group col-md-4">
            <label for="inputcognome">Cognome</label>
            <input for="text" class="form-control" id="inputPassword4" placeholder="Cognome" ngModel name="cognome" required>
          </div>-->
          <div class="form-group col-12">
            <label for="inputPassword4">Insegnante</label>
            <input type="text" class="form-control" id="inputPassword4" placeholder="Insegnante" ngModel name="insegnante" required>
          </div>
          <div class="form-group col-12">
            <label for="inputPassword4">Materia</label>
            <input type="text" class="form-control" id="inputPassword4" placeholder="Materia" ngModel name="materia" required>
          </div>
        </div>
        <p>Valutazione generale</p>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="primo" id="inlineRadio1" value="1" ngModel>
          <label class="form-check-label" for="inlineRadio1">1</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="primo" id="inlineRadio2" value="2" ngModel>
          <label class="form-check-label" for="inlineRadio2">2</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="primo" id="inlineRadio3" value="3" ngModel>
          <label class="form-check-label" for="inlineRadio3">3</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="primo" id="inlineRadio4" value="4" ngModel>
          <label class="form-check-label" for="inlineRadio4">4</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="primo" id="inlineRadio5" value="5" ngModel>
          <label class="form-check-label" for="inlineRadio5">5</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="primo" id="inlineRadio6" value="6" ngModel>
          <label class="form-check-label" for="inlineRadio6">6</label>
        </div>
        <hr>
        <div class="form-group">
          <label for="exampleFormControlTextarea1">Commenti</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="8" ngModel name="commenti"></textarea>
        </div>
        <hr>
        <button type="submit" class="btn btn-primary rounded-pill" [disabled]="!f.valid">Invia</button>
      </div>
      </form>

      <div [hidden]="!submitted" class="contact-message">
        <div class="contact-success">
          <div class="success">
            <h2>Congratulazioni!</h2>
            <h4>Grazie per averci fatto sapere la tua opinione.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `

})
export class NgbdModalRecensione {
  @ViewChild('f', {static : true}) signupForm: NgForm;
  BACKEND_URL = environment.apiUrl + 'contact/1/send_mail_recensioni/';
  constructor(public activeModal: NgbActiveModal, private dataStorageService: DataStorageService, private mailService: MailService) {}
  submitted = false;
  recensioni2 = {
    insegnante: '',
    materia: '',
    recensione: '',
    commenti: ''
  };
  recensioni = [
    {
      insegnante: 'prova',
      materia: 'prova',
      recensione: 'prova',
      commenti: 'prova'
    }
  ];

  onOp() {
    this.submitted = true;
    this.recensioni.push({
      insegnante: this.signupForm.value.opData2.insegnante,
      materia: this.signupForm.value.opData2.materia,
      recensione: this.signupForm.value.opData2.primo,
      commenti: this.signupForm.value.opData2.commenti
    });

    this.recensioni2.insegnante = this.signupForm.value.opData2.insegnante,
    this.recensioni2.materia = this.signupForm.value.opData2.materia,
    this.recensioni2.recensione = this.signupForm.value.opData2.primo,
    this.recensioni2.commenti = this.signupForm.value.opData2.commenti

    this.mailService.sendMail(this.BACKEND_URL, this.recensioni2);

    this.dataStorageService.storeRecens(this.recensioni)
      .subscribe(
        (response) => console.log(response),
        (error) => console.log(error)
      );

  this.signupForm.reset();
  }
}


@Component({
  selector: 'app-privato',
  templateUrl: './privato.component.html',
  styleUrls: ['./privato.component.scss']
})
export class PrivatoComponent implements OnInit {


  ngOnInit() {
  }

  constructor(private modalService: NgbModal) {}

  open() {
    const modalRef = this.modalService.open(NgbdModalPrivato);
  }

  open2() {
    const modalRef = this.modalService.open(NgbdModalRecensione);
  }

}
