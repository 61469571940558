import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { Http } from '@angular/http';

@Injectable()
export class AskStorageService {
    constructor(private http: HttpClient) {}
    storeAsk(ask: any[]) {
        return this.http.post('https://dante-ng-2piu2.firebaseio.com/ask.json', ask);
    }
}
