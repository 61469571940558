import { Component, OnInit, ViewChild } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'ngdb-modal-master',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Iscriviti!</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [hidden]="submitted" (ngSubmit)="onIscriv()" #f="ngForm">
        <div class="form" id="iscr-data" ngModelGroup="iscrivData" #iscrivData="ngModelGroup">
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" ngModel name="email" required email #email="ngModel">
          </div>
          <div class="form-group">
            <label for="nome">Nome</label>
            <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Nome" ngModel name="nome" required>
          </div>
          <div class="form-group">
            <label for="cognome">Cognome</label>
            <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Cognome" ngModel name="cognome" required>
          </div>
          <h5>Seleziona una classe</h5>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="terzo" id="inlineRadio1" value="3.1" ngModel (click)="onSel1()">
            <label class="form-check-label" for="inlineRadio1">Medie</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="terzo" id="inlineRadio2" value="3.2" ngModel (click)="onSel2()">
            <label class="form-check-label" for="inlineRadio2">Liceo</label>
          </div>
          <div [ngSwitch]="selezione" [hidden]="hidden">
            <div *ngSwitchCase="3.1">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" (click)="onMateria('Matemagica')">
                <label class="form-check-label" for="defaultCheck1">
                  Matemagica
                </label>
              </div>
            </div>
            <div *ngSwitchCase="3.2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" (click)="onMateria('Crittografifa')">
                <label class="form-check-label" for="defaultCheck1">
                  Introduzione alla crittografia
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('TeoGiochi')">
                <label class="form-check-label" for="defaultCheck2">
                  Introduzione alla teoria dei giochi
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('QuantPc')">
                <label class="form-check-label" for="defaultCheck2">
                  Introduzione ai computer quantistici
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Robotica')">
                <label class="form-check-label" for="defaultCheck2">
                  Robotica
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Quantum')">
                <label class="form-check-label" for="defaultCheck2">
                  Introduzione alla meccanica quantistica
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Matlab')">
                <label class="form-check-label" for="defaultCheck2">
                  Introduzione a Matlab
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck2" (click)="onMateria('Python')">
                <label class="form-check-label" for="defaultCheck2">
                  Introduzione a Python
                </label>
              </div>
            </div>
          </div>
        <br>
        <hr>
        <div class="form-group">
          <label for="nome">Sei in posssesso di buoni sconto?</label>
          <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Inserisci codice sconto" ngModel name="code">
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="!f.valid">Invia</button>
        </div>
      </form>
      <div [hidden]="!submitted" class="contact-message">
        <div class="contact-success">
          <div class="success">
            <h2>Congratulazioni!</h2>
            <h4>Grazie per averci contattati, ti risponderemo al più presto.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `

})
export class NgbdModalMaster {
  @ViewChild('f', {static : true}) signupForm: NgForm;

  hidden = true;

  nome = '';
  cognome = '';
  email = '';
  corso = '';
  select = '';
  constructor(public activeModal: NgbActiveModal) {}
  submitted = false;
  selezione = null;
  lezione = null;
  materia = [];

  onSel1() {
    this.hidden = false;
    this.selezione = '3.1';
  }
  onSel2() {
    this.hidden = false;
    this.selezione = '3.2';
  }
  onEx() {
    this.hidden = false;
  }

  onMateria(a) {
    this.materia.push(a);
  }

  onIscriv() {
    this.submitted = true;
    this.email = this.signupForm.value.iscrivData.email;
    this.nome = this.signupForm.value.iscrivData.nome;
    this.cognome = this.signupForm.value.iscrivData.cognome;
    //this.signupForm.reset();
  }
}




//import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit {

  isCollapsed1 = true;
  isCollapsed2 = true;
  isCollapsed3 = true;
  isCollapsed4 = true;
  isCollapsed5 = true;
  isCollapsed6 = true;
  isCollapsed7 = true;
  isCollapsed8 = true;
  isCollapsed9 = true;

  constructor(private modalService: NgbModal) {}

  open() {
    const modalRef = this.modalService.open(NgbdModalMaster);
  }

  ngOnInit() {
  }


}
