

<!--<body (mouseover)="onOpenPub()">-->
<body>



<div class="container-fluid"  id="titolo">


  <h1>2+2=0</h1>

<!--<h3>I tuoi corsi estivi di mate </h3>
<h3 class="animated zoomIn" id="special">su misura</h3>-->
  <!--<div class="row" style="padding-left: 33%">-->
  <div class="row" id="diff">
    <h3 style="text-align: center;padding-left:6%">Learn</h3>
    <h3 class="animate__animated animate__jackInTheBox" id="special" style="text-align:center; padding-left: 2%">different!</h3>
  </div>


</div>


<div class="col-12">
<app-mainbox></app-mainbox>
</div>

<div class="col-12">
  <app-privato-box></app-privato-box>
</div>

<!--<div class="col-12">
    <app-alto-box></app-alto-box>
</div>-->

<div class="col-12">
  <app-skype-box></app-skype-box>
</div>

<!--<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <app-master-box></app-master-box>
    </div>
    <div class="col-sm-12 col-md-6">
      <app-info-box></app-info-box>
    </div>
  </div>
</div>-->

<div class="col-12">
  <app-treedom-box></app-treedom-box>
</div>

<!--<div class="col-12">
  <app-online-box></app-online-box>
</div>-->

<div class="container-fluid">
  <div class="row" id="scatole">
    <div class="col-sm-12 col-md-6">
      <app-box-left></app-box-left>
    </div>


    <div class="col-sm-12 col-md-6">
    <!--<app-box-right></app-box-right>-->
      <app-join-box></app-join-box>
    </div>
  </div>

  <div class="col-12">
    <app-box-bottom></app-box-bottom>
  </div>

</div>
</body>
