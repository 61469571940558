<header id="TreedomHeader">
  <div class="container-fluid">

  <h1>
    Il nostro impegno ecologista
  </h1>

  </div>
</header>

<body>
  <!--<h1>Il Mese Verde di 2+2=0</h1>
  <br>
  <br>
  <p>Il 22 aprile si festeggia in tutto il mondo la giornata della Terra, una ricorrenza per ricordare l'importanza della salvaguardia dell'ambiente.</p>
  <p>In suo onore, 2+2=0 vi dà il benvenuto nel <strong>Mese Verde</strong>!</p>
  <p>Dal 01.04 al 30.04, ogni allievo che totalizzerà almeno 10 ore di lezione riceverà <strong>in regalo</strong> un albero attraverso l’iniziativa Treedom.
    Non esitate a contattare il vostro insegnante di fiducia presso 2+2=0 o a contattarci attraverso i canali disponibili sul sito
     <a routerLink="/Privato" id="qui">qui!</a> e <a routerLink="/Skype" id="qui">qui!</a>, e assicuratevi di pianificare le vostre lezioni.
    Partecipate così a questa iniziativa per un futuro più verde</p>
  <br>
  <p>Chiunque sia insignito di uno dei nostri alberi grazie alla promozione <strong>Mese Verde</strong> diverrà inoltre <strong>membro ad honorem del 3-Club</strong>!
    Essere membro del nuovissimo 3-Club dà diritto sia a sconti sui corsi speciali sia a ore di lezione gratuite, validi tutto l’anno!
    Trovate tutte le informazioni relative al 3-Club nella sezione dedicata (in arrivo), qui sul nostro sito!</p>
  <p>Stay tuned!</p>

  <br>
  <br>
  <br>-->



  <h1>Cosa facciamo</h1>
  <br>
  <br>
  <!--<p>Treedom è una piattaforma online che permette di piantare alberi a distanza e controllare il loro sviluppo e la loro crescita,
    monitorando la quantità di anidride carbonica assorbita dall'atmosfera così come l'aiuto allo sviluppo economico delle regioni in cui vengono piantati, essendo principalmente alberi da frutto.</p>
  <br>-->
  <!--<p>Per informazioni dettagliate sul funzionamento di Treedom e sui loro progetti vi rimandiamo al loro sito ufficiale:</p>
  <br>
  <br>
  <a href="https://www.treedom.net/it/" target="_blank" class="btn btn-primary btn-lg rounded-pill" id="bott">Treedom</a>
  <br>
  <br>-->
  <p>2+2=0 si impegna a piantare alberi a distanza per contrastare il deforestamento e contribuire all'assorbimento di gas ad effetto serra.</p>
  <br>
  <br>
  <!--<div class="container-fluid" id="nrore">
    <p>Mancano solo {{ ore }} ore al prossimo albero!</p>
    <p>Prenota subito la tua lezione</p>
  </div>
  <br>
  <br>-->
  <h1>La foresta di 2+2=0</h1>
  <br>
  <div class="container">
    <div class="row">
      <div class="col-6 col-xl-9 col-md-8">
        <p>Alcuni degli alberi piantati da noi sono visibili sui nostri profili Treedom e Tree-Nation.</p>
        <br>
        <p>Visitando la nostra foresta potete scoprire in quali zone del mondo sono stati piantati, come contribuiscono allo sviluppo economico della regione e tanto altro!</p>
        <p>Se vuoi saperne di più o se vuoi contribuire, non esitare a contattarci!</p>
      </div>
      <div class="col-3 col-xl-3 col-md-3" id="contorno">
  <!--<table class="table table-borderless table-hover table-success table-striped">
    <thead>
      <tr>
        <th scope="col"><img src="../../assets/2piu2.png" alt="" id="imm" class="pic"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>172<br>Alberi</td>
      </tr>
      <tr>
        <td>-21.36<br>t di CO2</td>
      </tr>
      <tr>
        <td>9<br>Paesi</td>
      </tr>
    </tbody>
  </table>-->
  </div>
  </div>
  </div>

  <!--<a href="https://www.treedom.net/it/organization/anonymous_ab666b00" target="_blank" class="btn btn-primary btn-lg rounded-pill">Visita la nostra foresta</a>-->
  <br>
  <br>
  <!--<h1>Come puoi contribuire?</h1>
  <p>Oltre a partecipare alle lezioni puoi finanziare direttamente l'acquisto di un albero grazie a delle
    donazioni tramite la nostra pagina su Ko-Fi. Qui potrai contribuire direttamente partecipando a una
    delle raccolte da noi iniziate oppure in modo generale donando l'importo di tua scelta e scrivendo
    "Treedom" nel messaggio.
  </p>
  <br>
  <br>
  <a href="https://ko-fi.com/2piu2uguale0" target="_blank" class="btn btn-primary btn-lg rounded-pill"><img src="../../assets/Ko-fi_Logo_RGB_DarkBg.png" class="figure-img img-fluid" id="ko"></a>
  <br>
  <br>-->
  <h3>3-Club</h3>
  <br>
  <br>
  <p>Finanziando l'acquisto di un albero avrai diritto alla partecipazione al <strong>3-Club</strong> di 2+2=0</p>
  <br>
  <h6>Scopri di più sul Club:</h6>
  <a routerLink="/Club" class="btn btn-success btn-lg rounded-pill"><i class="fas fa-leaf animate__animated animate__zoomInLeft animate__infinite animate__slower"></i>3-Club</a>
  <br>
  <br>
  <br>
  <br>
  <h3>Altri suggerimenti</h3>
  <br>
  <br>
  <p>Se sei interessato ad altre iniziative sostenibili guarda qua:</p>
  <br>
  <h5>Circular Lugano:</h5><a href="https://www.circular-lugano.ch/" target="_blank"><img src="assets/Logo-Vector.png" class="d-inline-block align-top" alt="" id="circular"></a>


</body>
