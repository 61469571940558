import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class JoinStorageService {
    constructor(private http: HttpClient) {}
    storeJoin(insegnanti: any[]) {
        return this.http.post('https://dante-ng-2piu2.firebaseio.com/insegnanti.json', insegnanti);
    }
}
