<div class="masterboxsfondo">
  <a [routerLink]="['ChiSiamo']" fragment="join">
  <div class="container-fluid" id="boxj">
    <div class="row" id="rigaj">
      <div class="col-12">
        Join us!
      </div>
    </div>
    <!--<p id="testo">offriamo corsi di matematica su misura a Giubiasco</p>-->
  </div>

</a>
</div>
