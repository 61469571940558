<app-head titolo="Lezioni private"></app-head>

<body>

  <h2>Come funziona</h2>
  <br>
  <p>Si tratta di lezioni di ripetizione nell'accezione classica del termine: faccia a faccia con il docente.</p>
   <p>Le lezioni si concentrano sul consolidamento della teoria vista in classe e sul potenziamento delle conoscenze tramite esercizi.</p>
   <p>Il percorso varia a dipendenza dei bisogni individuali di ogni allievo.</p>

   <p>A seconda delle preferenze dell'allievo le lezioni si possono svolgere al suo domicilio, in un luogo concordato, a domicilio dal docente oppure online (guarda la nostra sezione<a routerLink="/Skype"><button type="button" class="btn btn-link">Lezioni online</button></a>per maggiori informazioni).<br>
    </p>
    <br>
  <h2>Costo delle lezioni</h2>
  <br>
  <p>Il costo varia in base al numero di allievi che partecipano, il prezzo indicato è per il totale di allievi che partecipano. Più allievi ci sono, quindi, meno verrà a costare la lezione per ogni allievo.</p>
  <p>Portare uno o più amici che hanno bisogno delle stesse ripetizioni conviene a tutti! </p>
  <br>

  <table class="table">
    <thead>
      <tr>
        <th scope="col">#Allievi</th>
        <th scope="col">Costo</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">1</th>
        <td>35 CHF</td>
      </tr>
      <tr>
        <th scope="row">2</th>
        <td>25 CHF a testa</td>
      </tr>
      <tr>
        <th scope="row">3</th>
        <td>20 CHF a testa</td>
      </tr>
      <tr>
        <th scope="row">4</th>
        <td>17 CHF a testa</td>
      </tr>
    </tbody>
  </table>


  <button type="button" class="btn btn-primary btn-lg rounded-pill" (click)="open()">Iscriviti!</button>
  <br>
  <br>
  <br>
  <h2>I nostri pacchetti scontati</h2>
  <br>
  <p>Stipulando uno dei nostri pacchetti potrete risparmiare su ogni ora di lezione svolta</p>
  <br>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">#Ore</th>
        <th scope="col" class="pc">Nome</th>
        <th scope="col">Costo orario</th>
        <th scope="col">Limite di tempo</th>
      </tr>
    </thead>
    <tbody>
      <!--<tr>
        <th scope="row">20</th>
        <td class="pc">20 no limits</td>
        <td>34CHF <br class="capo"><span id="meno">-20CHF</span></td>
        <td>4 mesi</td>
      </tr>-->
      <tr>
        <th scope="row">20</th>
        <td class="pc">20 classic</td>
        <td>33.50CHF <br class="capo"><span id="meno">-30CHF</span></td>
        <td>2.5 mesi</td>
      </tr>
      <!--<tr>
        <th scope="row">30</th>
        <td>Semestrale</td>
        <td>33.30CHF</td>
        <td>4 mesi (offerta limitata)</td>
      </tr>
      <tr>
        <th scope="row">40</th>
        <td class="pc">40 intense</td>
        <td>29.90CHF <br class="capo"><span id="meno">-204CHF</span></td>
        <td>3 mesi</td>
      </tr>
      <tr>
        <th scope="row">60</th>
        <td class="pc">60 extreme</td>
        <td>29CHF <br class="capo"><span id="meno">-360CHF</span></td>
        <td>3 mesi</td>
      </tr>-->
    </tbody>
  </table>
  <br>
  <p>I pacchetti possono essere personalizzati su misura nel caso in cui ci fossero esigenze particolari o la volontà di partecipare in due o più allievi. <small>È richiesto un pagamento anticipato</small></p>

  <br>
<br>
  <br>
  <h3>La tua opinione è importante!</h3>
  <br>
  <p>Facci sapere cosa pensi del nostro servizio riempiendo il modulo di recensione sottostante, ogni opinione conta per noi!
  </p>
  <br>

  <button type="button" class="btn btn-primary btn-lg rounded-pill" (click)="open2()" id="recens">Recensione</button>

  <br>
  <br>
  <br>
  <!--<h3>Novità di quest'anno!</h3>
  <br>
  <p>Non perdere l'occasione di avere 3 lezioni di zumba omaggio!</p>
  <br>
  <div class="container-fluid">
    <img src="../assets/zumba2.jpeg" alt="pietro-zumba" loading="lazy">
    <br>
    <br>
    <a type="button" class="btn btn-primary btn-lg rounded-pill" href="https://www.linktr.ee/pietrozumba" target="_blank" id="pietro">Approfittane!</a>
  </div>-->
</body>
