<div class="masterboxsfondo">
  <a routerLink="Master">
  <div class="container-fluid" id="boxmt">
    <div class="row" id="rigamt">
      <div class="col-12">
        Master Classes
      </div>
    </div>
    <!--<p id="testo">offriamo corsi di matematica su misura a Giubiasco</p>-->
  </div>

</a>
</div>
