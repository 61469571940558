<ngb-carousel #carousel [interval]="2500" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
  <ng-template ngbSlide>
    <div class="carousel-caption">
      <i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i>
        <i class="fas fa-star fa-2x"></i>
    </div>
    <div class="picsum-img-wrapper">
      <div class="row">
        <!--<div class="col-1"><i class="fas fa-user-circle fa-5x"></i></div>-->
        <div class="col-11 col-xs-8">
          <p>"Ottime spiegazioni. Imparo più qui che a scuola.</p>
          <p>Molto cordiale e disponibile! Grazie!"
          </p>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template ngbSlide>
    <div class="carousel-caption">
      <i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i>
        <i class="fas fa-star fa-2x"></i>
    </div>
    <div class="picsum-img-wrapper">
      <div class="row">
        <!--<div class="col-1"><i class="fas fa-user-circle fa-5x"></i></div>-->
        <div class="col-11 col-xs-8">
          <p>"Durante le lezioni mi sono trovato davvero bene: <br>l'insegnante è stato veramente disponibile, paziente e competente.</p>
          <p>Le sue spiegazioni risultavano semplici e complete.</p>
          <p>Grazie mille per l'aiuto."
          </p>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template ngbSlide>
    <div class="carousel-caption">
      <i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i>
        <i class="fas fa-star fa-2x"></i>
    </div>
    <div class="picsum-img-wrapper">
      <div class="row">
        <!--<div class="col-1"><i class="fas fa-user-circle fa-5x"></i></div>-->
        <div class="col-11 col-xs-8">
          <p>"La docente molto brava e preparata.</p>
          <p>In caso di bisogno vi contatteremo sicuramente di nuovo.</p>
          <p>Grazie!"
          </p>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template ngbSlide>
    <div class="carousel-caption">
      <i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i>
        <i class="fas fa-star fa-2x"></i>
    </div>
    <div class="picsum-img-wrapper">
      <div class="row">
        <!--<div class="col-1"><i class="fas fa-user-circle fa-5x"></i></div>-->
        <div class="col-11 col-xs-8">
          <p>"Puntuale, chiaro, sempre disponibile.</p>
          <p> E soprattutto efficace perché mia figlia <br>ha recuperato un po' di
            fiducia e finalmente<br> è riuscita ad avere la sufficienza!"
          </p>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template ngbSlide>
    <div class="carousel-caption">
      <i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i>
        <i class="fas fa-star fa-2x"></i>
    </div>
    <div class="picsum-img-wrapper">
      <div class="row">
        <!--<div class="col-1"><i class="fas fa-user-circle fa-5x"></i></div>-->
        <div class="col-11 col-xs-8">
          <p>"L'insegnante era molto preparata e organizzata.</p>
          <p>Le lezioni di materie linguistiche funzionano <br>molto bene anche online."
          </p>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template ngbSlide>
    <div class="carousel-caption">
      <i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i>
        <i class="fas fa-star fa-2x"></i>
    </div>
    <div class="picsum-img-wrapper">
      <div class="row">
        <!--<div class="col-1"><i class="fas fa-user-circle fa-5x"></i></div>-->
        <div class="col-11 col-xs-8">
          <p>"Abbiamo deciso di utilizzare questo metodo di studio <br>molto interessante </p>
          <p>perché mirato ai problemi del singolo studente."</p>
          <!--<p>Grazie mille per l'aiuto."
          </p>-->
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template ngbSlide>
    <div class="carousel-caption">
      <i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i>
        <i class="fas fa-star fa-2x"></i>
    </div>
    <div class="picsum-img-wrapper">
      <div class="row">
        <!--<div class="col-1"><i class="fas fa-user-circle fa-5x"></i></div>-->
        <div class="col-11 col-xs-8">
          <p>"Da quando fa lezioni Matilda ha preso sicurezza <br>e fiducia in sé stessa.</p>
          <p>l'insegnante ci mette passione e si vede pure che fa <br>ricerche per come aiutarla.</p>
          <p>Grazie del vostro impegno, <br>siete oro per noi genitori"
          </p>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="carousel-caption">
      <i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i><i class="fas fa-star fa-2x"></i>
        <i class="fas fa-star fa-2x"></i>
    </div>
    <div class="picsum-img-wrapper">
      <div class="row">
        <!--<div class="col-1"><i class="fas fa-user-circle fa-5x"></i></div>-->
        <div class="col-11 col-xs-8">
          <p>"Fantastica! <br>mi ha insegnato molto e ho capito tutto.</p>
          <p>mi ha aiutato a ripetere quello che <br>mi serviva e mi ha chiarito</p>
          <p>quello che non avevo capito"
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</ngb-carousel>
