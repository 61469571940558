<app-head titolo="Smart Teaching"></app-head>

<body>
  <h1>Cosa facciamo</h1>
  <p>Questa sezione è completamente dedicata allo studio online. Grazie all'utilizzo di varie app
    completamente gratuite abbiamo sviluppato delle classi virtuali a cui puoi partecipare svolgendo
    esercizi, contribuendo alla discussione degli stessi così come ponendo domande su un determinato tema
    o richiedendo più esercizi ai nostri insegnanti.
  </p>
  <br>
  <br>

  <h1>Come funziona</h1>
  <p>Basta scaricare e creare un account su Quizlet per avere accesso a tutti gli esercizi
    che carichiamo in modo completamente gratuito.
    I nostri insegnanti monitoreranno le risoluzioni degli esercizi e i progressi
    dei ragazzi attraverso i vari set da noi preparati.
    Offriamo poi la possibilità (tramite Discord) di partecipare al nostro forum di discussione.
    L'idea è di poter discutere di problemi mirati grazie all'aiuto dei nostri insegnanti (che fungono anche
    da moderatori delle discussioni) e dei ragazzi più grandi. Iscrivendoti hai infatti la possibilità
    di partecipare a tutti i canali sul forum, non solo a quelli per cui hai bisogno di aiuto. In questo modo
    sulla stessa piattaforma potrai sia ricevere aiuto, da insegnanti e da ragazzi più grandi, sia aiutare i
    ragazzi più giovani e con più difficoltà.
    Il miglior modo di imparare è provare a spiegare!
  </p>
  <br>
  <br>

  <div class="row" id="pc">
    <div class="col-md-6 col-sm-12">
      <a href="https://discordapp.com/download" target="_blank" rel="noopener noreferrer" class="btn btn-light btn-lg btn-block"><i class="fab fa-discord fa-3x"></i> Discord</a>
    </div>

    <div class="col-md-6 col-sm-12">
      <a href="https://quizlet.com/it" target="_blank" rel="noopener noreferrer" class="btn btn-light btn-lg btn-block"><img src="../../assets/LogoMakr_1F1hcl.png" class="figure-img img-fluid" style="height: 55px;">Quizlet</a>
    </div>
  </div>

  <div class="container" id="iphone">
    <div class="row"><i class="fab fa-discord fa-3x" style="margin: 15px;"></i> <h2>Discord</h2></div>
    <br>
    <div class="row">
        <div class="col-12">
          <a href="https://discordapp.com/download" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-app-store-ios"></i>Installa</a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <a href="https://discordapp.com/download" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-google-play"></i>Installa</a>
        </div>
      </div>
    <br>
    <hr>
    <div class="row"><img src="../../assets/LogoMakr_1dRFZh.png" class="figure-img img-fluid" style="height: 45px;"><h2>Quizlet</h2></div>
    <br>
    <div class="row">
      <div class="col-12">
        <a href="https://apps.apple.com/us/app/quizlet/id546473125" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-app-store-ios"></i>Installa</a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <a href="https://play.google.com/store/apps/details?id=com.quizlet.quizletandroid&hl=it" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-google-play"></i>Installa</a>
      </div>
    </div>
  </div>

  <div class="container" id="ipad">
    <div class="row"><i class="fab fa-discord fa-5x" style="margin: 15px;"></i> <h2>Discord</h2></div>
    <br>
    <div class="row">
        <div class="col-6">
          <a href="https://discordapp.com/download" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-app-store-ios"></i>Installa</a>
        </div>

        <div class="col-6">
          <a href="https://discordapp.com/download" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-google-play"></i>Installa</a>
        </div>
      </div>
    <br>
    <hr>
    <div class="row"><img src="../../assets/LogoMakr_1dRFZh.png" class="figure-img img-fluid" style="height: 45px;"><h2>Quizlet</h2></div>
    <br>
    <div class="row">
      <div class="col-6">
        <a href="https://apps.apple.com/us/app/quizlet/id546473125" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-app-store-ios"></i>Installa</a>
      </div>

      <div class="col-6">
        <a href="https://play.google.com/store/apps/details?id=com.quizlet.quizletandroid&hl=it" target="_blank" rel="noopener noreferrer" class="btn btn-dark btn-lg btn-block"><i class="fab fa-google-play"></i>Installa</a>
      </div>
    </div>
  </div>

  <br>
  <br>
  <h3>Iscriviti alla nostra classe di Quizlet</h3>
  <p>Il link seguente ti permette di avere accesso alla classe virtuale su Quizlet.
    Qui potrai trovare tutti gli esercizi di cui hai bisogno.
  </p>
  <br>
  <br>
  <button class="btn btn-primary btn-lg rounded-pill" id="bott" (click)="onOpenQuizlet()">Join us on Quizlet!</button>
  <br>
  <br>


  <h3>Unisciti alla community</h3>
  <p>Dopo aver creato un account su Discord tutto quello che devi fare è unirti alla nostra community tramite
    il link qui sotto.
  </p>

  <a href="https://discord.gg/zdU8Dk8" target="_blank" class="btn btn-primary btn-lg rounded-pill" id="bott">Join us on Discord!</a>
  <br>
  <br>
  <br>
  <p id="condizioni">Per motivi di sicurezza volti a evitare lo spam il regolamento prevede un'attesa di 10 minuti
    tra l'iscrizione al forum e il primo messaggio che puoi scrivere, sarai comunque in grado di leggere tutto
    quello che gli altri hanno scritto fino a quel momento. Ti ringraziamo per la comprensione.
  </p>
  <br>
  <br>

  <h1>Sostieni il nostro progetto!</h1>
  <p>Tutta questa sezione è completamente gratuita!
    Noi e i nostri insegnanti mettiamo però a disposizione il nostro tempo
    per aiutarvi con i compiti e gli esercizi da noi proposti.
    Saremmo quindi grati per qualunque offerta da parte vostra per finanziare
    almeno in parte questo servizio.</p>
  <p>Per ulteriori informazioni visita la pagina seguente dove è possibile effettuare direttamente le
    donazioni anche per altri nostri progetti
  </p>
  <a href="https://ko-fi.com/2piu2uguale0" target="_blank" class="btn btn-primary btn-lg rounded-pill"><img src="../../assets/Ko-fi_Logo_RGB_DarkBg.png" class="figure-img img-fluid" id="ko"></a>
  <br>
  <br>
  <p>È possibile inviare donazioni anche tramite PayPal e Twint</p>
  <button class="btn btn-primary btn-lg rounded-pill" (click)="onOpenPay()"><i class="fab fa-paypal"></i> PayPal</button>
  <br>
  <br>
  <br>
  <button class="btn btn-primary btn-lg rounded-pill" (click)="onOpenTwint()">TWINT</button>

</body>
