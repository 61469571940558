
<header>
  <div class="container-fluid" id="DoveHeader">

  <h1>
    Master classes
  </h1>

  </div>
</header>

<body>


<h1 class="animated lightSpeedIn" id="special">Are you smart enough?</h1>
<br>
<h2>Idea alla base</h2>
<br>
<p>Le ripetizioni sono solo per chi fa fatica? Da oggi no!</p>
<p> Per chi non trova abbastanza stimoli a scuola abbiamo concepito dei cicli di lezioni in cui esporre
  materiale di grande rilevanza in una società digitale e in forte espansione come quella in cui siamo immersi.

</p>
<br>
<h2>Come funziona</h2>
<br>
<p>A dipendenza del corso scelto la lezione può essere individuale o a gruppi con un minimo di partecipanti.
  Leggi la descrizione per capire come iscriverti.
</p>
<br>

<h3>Medie</h3>
<h5>Mate:</h5>
<ul>
  <p>
    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed1 = !isCollapsed1"
      [attr.aria-expanded]="!isCollapsed1" aria-controls="collapseExample">
      Mate magica <i class="fas fa-chevron-down" [hidden]="!isCollapsed1"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed1"></i>
    </button>
  </p>
    <div id="collapseExample" [ngbCollapse]="isCollapsed1">
      <div class="card">
        <div class="card-body">
          Una serie di lezioni per vedere il lato interessante e divertente della matematica l' analisi di "trucchi matemagici".<br>Durante le lezioni si analizzerà il funzionamento di una serie di trucchi (di carte e numerici) basati sulla matematica e se ne svilupperanno insieme di nuovi.<br>Le lezioni possono essere indifferentemente sia individuali sia di gruppo.<br>La tariffa oraria è la stessa delle lezioni classiche.
        </div>
      </div>
    </div>
</ul>
<hr>
<h5>Scienze:</h5>
<hr>

<h3>Liceo</h3>
<h5>Mate:</h5>
<ul>
  <p>
    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed2 = !isCollapsed2"
      [attr.aria-expanded]="!isCollapsed2" aria-controls="collapseExample">
        Introduzione alla crittografia <i class="fas fa-chevron-down" [hidden]="!isCollapsed2"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed2"></i>
    </button>
    </p>
      <div id="collapseExample" [ngbCollapse]="isCollapsed2">
        <div class="card">
          <div class="card-body">
            Una serie di lezioni per introdurre il concetto di crittografia.<br>Durante le lezioni si comincerà vedendo la teoria alla base della crittografia per poi passare a degli esempi pratici.<br>Le lezioni saranno svolte in gruppo, da 3 a 5 persone.<br>Il costo sarà di: per un ciclo di lezioni di ore.
          </div>
        </div>
      </div>

      <p>
        <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed3 = !isCollapsed3"
          [attr.aria-expanded]="!isCollapsed3" aria-controls="collapseExample">
            Introduzione alla teoria dei giochi <i class="fas fa-chevron-down" [hidden]="!isCollapsed3"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed3"></i>
        </button>
        </p>
          <div id="collapseExample" [ngbCollapse]="isCollapsed3">
            <div class="card">
              <div class="card-body">
                Una serie di lezioni per introdurre il concetto di teoria dei giochi.<br>Durante le lezioni si comincerà vedendo la teoria alla base della teoria dei giochi per poi passare a delle applicazioni a situazioni reali.<br>Le lezioni saranno svolte in gruppo, da 3 a 5 persone.<br>Il costo sarà di: per un ciclo di lezioni di ore.
              </div>
            </div>
          </div>
</ul>
<hr>
<h5>Fisica:</h5>
<ul>
  <p>
    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed4 = !isCollapsed4"
      [attr.aria-expanded]="!isCollapsed4" aria-controls="collapseExample">
        Introduzione ai computer quantistici <i class="fas fa-chevron-down" [hidden]="!isCollapsed4"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed4"></i>
    </button>
    </p>
      <div id="collapseExample" [ngbCollapse]="isCollapsed4">
        <div class="card">
          <div class="card-body">
            Una serie di lezioni per introdurre il concetto di crittografia.<br>Durante le lezioni si comincerà vedendo la teoria alla base della crittografia per poi passare a degli esempi pratici.<br>Le lezioni saranno svolte in gruppo, da 3 a 5 persone.<br>Il costo sarà di: per un ciclo di lezioni di ore.
          </div>
        </div>
      </div>
  <p>
    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed5 = !isCollapsed5"
      [attr.aria-expanded]="!isCollapsed5" aria-controls="collapseExample">
        Robotica <i class="fas fa-chevron-down" [hidden]="!isCollapsed5"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed5"></i>
    </button>
  </p>
    <div id="collapseExample" [ngbCollapse]="isCollapsed5">
      <div class="card">
        <div class="card-body">
          Una serie di lezioni per cominciare a vedere come funziona la robotica.<br>Durante le lezioni si comincerà vedendo come funzionano i circuiti alla base del funzionamento dei programmi di robotica. Si passerà poi al lato pratico con la programmazione dei circuiti e la loro realizzazione applicata a degli esempi pratici di robotica. Per questo scopo useremo il programma Arduino.<br>Le lezioni saranno svolte in gruppo, da 3 a 5 persone.<br>Il costo sarà di: per un ciclo di lezioni di ore.
        </div>
      </div>
    </div>

  <p>
    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed6 = !isCollapsed6"
      [attr.aria-expanded]="!isCollapsed6" aria-controls="collapseExample">
        Introduzione alla meccanica quantistica <i class="fas fa-chevron-down" [hidden]="!isCollapsed6"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed6"></i>
    </button>
  </p>
    <div id="collapseExample" [ngbCollapse]="isCollapsed6">
      <div class="card">
        <div class="card-body">
          Una serie di lezioni per introdurre il concetto di meccanica quantistica.<br>Durante le lezioni si comincerà vedendo gli esperimenti che hanno portato alla formulazione della teoria della meccanica quantistica.<br>Le lezioni possono essere indifferentemente sia individuali sia di gruppo.<br>La tariffa oraria è la stessa delle lezioni classiche.
        </div>
      </div>
    </div>
</ul>
<hr>
<h5>Informatica:</h5>
<ul>
  <p>
    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed7 = !isCollapsed7"
      [attr.aria-expanded]="!isCollapsed7" aria-controls="collapseExample">
        Introduzione a Matlab <i class="fas fa-chevron-down" [hidden]="!isCollapsed7"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed7"></i>
    </button>
  </p>
    <div id="collapseExample" [ngbCollapse]="isCollapsed7">
      <div class="card">
        <div class="card-body">
          Una serie di lezioni per introdurre il concetto di programmazione in Matlab.<br>Durante le lezioni si comincerà vedendo le basi della programmazione in Matlab per proseguire poi a implementare i programmi classici di analisi numerica come al primo anno di uni.<br>Le lezioni possono essere indifferentemente sia individuali sia di gruppo.<br>La tariffa oraria è la stessa delle lezioni classiche.
        </div>
      </div>
    </div>
  <p>
    <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed8 = !isCollapsed8"
      [attr.aria-expanded]="!isCollapsed8" aria-controls="collapseExample">
        Introduzione a Python <i class="fas fa-chevron-down" [hidden]="!isCollapsed8"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed8"></i>
    </button>
  </p>
    <div id="collapseExample" [ngbCollapse]="isCollapsed8">
      <div class="card">
        <div class="card-body">
          Una serie di lezioni per introdurre il concetto di programmazione in Python.<br>Durante le lezioni si comincerà vedendo le basi della programmazione in Python per proseguire poi a implementare i programmi classici di analisi numerica come al primo anno di uni.<br>Le lezioni possono essere indifferentemente sia individuali sia di gruppo.<br>La tariffa oraria è la stessa delle lezioni classiche.
        </div>
      </div>
    </div>
    <p>
      <button type="button" class="btn btn-outline-link btn-lg btn-block " (click)="isCollapsed9 = !isCollapsed9"
        [attr.aria-expanded]="!isCollapsed9" aria-controls="collapseExample">
          Introduzione a LaTex <i class="fas fa-chevron-down" [hidden]="!isCollapsed9"></i> <i class="fas fa-chevron-up" [hidden]="isCollapsed9"></i>
      </button>
    </p>
      <div id="collapseExample" [ngbCollapse]="isCollapsed9">
        <div class="card">
          <div class="card-body">
            Una serie di lezioni per imparare le basi della scrittura in LaTex.<br>Durante le lezioni si partirà dalle basi tramite dei template preimpostati per arrivare a imparare a scivere un paper completo da zero.<br>Le lezioni possono essere indifferentemente sia individuali sia di gruppo.<br>La tariffa oraria è la stessa delle lezioni classiche.
          </div>
        </div>
      </div>
</ul>
<button type="button" class="btn btn-primary btn-lg rounded-pill" (click)="open()">Iscriviti!</button>
</body>
